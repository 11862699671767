/* eslint-disable max-len */
import React, { CSSProperties } from 'react'
import cls from 'classnames'
import { ICustomIconProps } from 'types/common'
import styles from './xsocial.module.scss'

function XSocial({ hovered, width, size, color, isMobile, viewBox }: Readonly<ICustomIconProps>) {
  const renderColor = () => {
    if (hovered) return '#ffffff'
    return color || '#575757'
  }

  const renderStyle = (): CSSProperties | undefined => {
    if (size === 'sm') return { transform: 'scale(0.6)' }
    return undefined
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || '0 0 16 16'}
      width={width}
      className={cls({ [styles.hovered]: hovered })}
      style={renderStyle()}
    >
      {isMobile ? (
        <path
          fill="black"
          fillRule="evenodd"
          d="M31.3195 0H37.4108L24.1031 15.2492L39.7586 36H27.5005L17.8995 23.4148L6.91383 36H0.818853L15.0527 19.6892L0.0344238 0H12.6037L21.2821 11.5034L31.3195 0ZM29.1817 32.3446H32.5569L10.7697 3.46338H7.14768L29.1817 32.3446Z"
        />
      ) : (
        <path
          fill={renderColor()}
          fillRule="evenodd"
          id="Vector"
          d="M12.2179 1.26953H14.4668L9.55347 6.88519L15.3337 14.5268H10.8078L7.26302 9.89222L3.20696 14.5268H0.956613L6.21195 8.52026L0.666992 1.26953H5.30773L8.51192 5.50575L12.2179 1.26953ZM11.4285 13.1807H12.6747L4.63059 2.54495H3.2933L11.4285 13.1807Z"
        />
      )}
    </svg>
  )
}

export default React.memo(XSocial)
