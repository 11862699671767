export enum COMMON_ACTION_TYPES {
  FETCH_CURRENCIES_REQUEST = 'FETCH_CURRENCIES_REQUEST',
  FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS',
  FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE',

  FETCH_CURRENCIES_PRICE_UPDATE = 'FETCH_CURRENCIES_PRICE_UPDATE',

  SET_UPLOAD_PHOTO_MODAL_STATE = 'SET_UPLOAD_PHOTO_MODAL_STATE',
}

export type TCurrenciesPayload = {
  limit: number
  page: number
  type: 'fiat' | 'coin'
  search: { code: string; name: string }
}
