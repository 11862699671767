import React, { memo } from 'react'
import isEqual from 'react-fast-compare'
import Loader from '@components/Loader'
import Pagination from '@components/UiKit/Pagination'
import { TNullable } from 'types/utilityTypes'
import { TEmojiItemPurchased } from '@core/redux/emojis/types'
import SearchSort from '@components/SearchSort'
import { AVAILABLE_FUNCTIONALITY } from '@constants/settings'

import ProfilePurchasedNoData from '../NoDataPurchased'
import PurchasedContent from './PurchasedContent'
import { DEFAULT_ITEMS_COUNT_PER_PAGE } from './Purchased.config'
import styles from './style.module.scss'

export interface PropsProfilePurchased {
  loading?: boolean
  items?: TEmojiItemPurchased[]
  isLargeCard?: boolean
  currentCard: TNullable<TEmojiItemPurchased>
  onClickCard: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: TEmojiItemPurchased,
    order: number,
  ) => void
  orderDetails?: number
  onSetHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, code: string) => void
  setCurrentCard:
    | React.Dispatch<React.SetStateAction<TNullable<TEmojiItemPurchased>>>
    | ((value: TNullable<TEmojiItemPurchased>) => void)
  setIsLargeCard: React.Dispatch<React.SetStateAction<boolean>> | ((value: boolean) => void)
  itemsCountPerPage?: number
  total?: number
  handlePageChange?: (currentPage: { selected: number }) => void
  page?: number
  noData?: boolean
}

function ProfilePurchased({
  loading,
  items,
  isLargeCard,
  currentCard,
  onClickCard,
  orderDetails,
  onSetHandler,
  setCurrentCard,
  setIsLargeCard,
  itemsCountPerPage,
  total,
  handlePageChange,
  page,
  noData,
}: Readonly<PropsProfilePurchased>) {
  return (
    <Loader isMountVisibleContent center loading={loading ?? false} height="200px">
      {AVAILABLE_FUNCTIONALITY.profileControlsPanel && (
        <div className={styles.header}>
          <SearchSort isDropdownDisabled disabled onSearch={() => {}} action={setIsLargeCard} />
        </div>
      )}

      <PurchasedContent
        data={items}
        isLargeCard={isLargeCard}
        selectedCard={currentCard}
        onClick={onClickCard}
        orderDetails={orderDetails}
        onSetHandler={onSetHandler}
        onSelectCard={setCurrentCard}
      />

      {noData && <ProfilePurchasedNoData />}

      <Pagination
        itemsPerPage={itemsCountPerPage || DEFAULT_ITEMS_COUNT_PER_PAGE}
        total={total}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </Loader>
  )
}

export default memo(ProfilePurchased, isEqual)
