import { all, call, put, takeLatest } from 'redux-saga/effects'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import { ApiVersion, IApiConfig } from '@core/api/types'
import { API } from '@core/api'
import { API_URLS } from '@core/api/apiUrls'
import { TParams } from '@core/type'
import { TEmojiItemPurchased } from '@core/redux/emojis/types'
import { getErrorTextHandler } from '@core/helper'
import { ITransactionTypes } from '@components/Profile/Transactions/main/types'
import {
  fetchPurchasedTokensSuccess,
  fetchPurchasedTokensFailure,
  fetchTransactionsSuccess,
  fetchTransactionsFailure,
} from './actions'
import { PURCHASED_TOKENS_TYPES } from './types'

const peatioConfig: IApiConfig = {
  apiVersion: ApiVersion.peatio,
  withHeaders: true,
}

type Arguments<T> = {
  type: string
} & T

export const TRANSACTION_URLS_BY_TYPE = {
  [ITransactionTypes.recentActivities]: API_URLS.account.transactions.dashboard,
  [ITransactionTypes.cryptoTX]: API_URLS.account.transactions.adjustments,
  [ITransactionTypes.quote]: API_URLS.account.transactions.quotes,
  [ITransactionTypes.withdraw]: API_URLS.account.transactions.withdraws,
  [ITransactionTypes.purchase]: API_URLS.account.transactions.banxa,
  [ITransactionTypes.bonus]: API_URLS.account.transactions.bonus,
  [ITransactionTypes.nfe]: API_URLS.token.purchase,
  [ITransactionTypes.wonkabots]: API_URLS.wonka.listTokens,
}

function* fetchPurchasedTokens({ params }: Arguments<{ params: TParams }>) {
  try {
    const url = queryString.stringifyUrl({
      url: API_URLS.token.collected,
      query: params,
    })

    const { data, headers } = yield call(API.get<TEmojiItemPurchased[]>(peatioConfig), url)

    const preparedData = {
      items: data || [],
      total: Number(headers.total),
      page: Number(headers.page),
      perPage: Number(headers['per-page']),
    }

    yield put(fetchPurchasedTokensSuccess(preparedData))
  } catch (err: any) {
    const messageText = getErrorTextHandler(err)
    toast.error(messageText)
    yield put(fetchPurchasedTokensFailure(err))
  }
}

function* fetchTransactions({
  payload: { params, transactionType },
}: Arguments<{ payload: { params: TParams; transactionType: ITransactionTypes } }>) {
  try {
    const url = queryString.stringifyUrl({
      url: TRANSACTION_URLS_BY_TYPE[transactionType],
      query: params,
    })

    const { data, headers } = yield call(API.get(peatioConfig), url)

    const preparedData = {
      items: data || [],
      total: headers.total ? Number(headers.total) : null,
      page: headers.page ? Number(headers.page) : null,
      perPage: headers['per-page'] ? Number(headers['per-page']) : null,
    }

    yield put(fetchTransactionsSuccess({ transactionType, payload: preparedData }))
  } catch (err: any) {
    const messageText = getErrorTextHandler(err)
    toast.error(messageText)
    yield put(fetchTransactionsFailure({ transactionType, error: err }))
  }
}

export function* rootSagaPurchasedTokens() {
  yield all([takeLatest(PURCHASED_TOKENS_TYPES.GET_PURCHASED_TOKENS_REQUEST, fetchPurchasedTokens)])
  yield all([takeLatest(PURCHASED_TOKENS_TYPES.GET_TRANSACTIONS_REQUEST, fetchTransactions)])
}
