import { IEnv } from './types'

const env: IEnv = {
  title: 'Playa',
  minutesUntilAutoLogout: '60',
  withCredentials: true,
  defaultStorageLimit: 50,
  chainid: 1,
  defaultCurrency: 'vne',
  defaultCurrencyName: 'Vine',
  googleKey: 'G-5WJ1JL36P9',
  termsOfUseVersion: 1,
  walletConnectProjectID: '9f69a2239620d28b5a106e8c13b31cc9',
  refid: 'ID6BE1887B18',
  removePeer: 'true',
  peerclubUrl: 'https://pc.peerclub.com',
  //
  captcha: {
    captchaType: 'turnstile',
    siteKey: '0x4AAAAAAADQoMhQrjV710_v',
    key: '0x4AAAAAAAbAm0h_uxyk0kYR',
  },
  //
  stripeKey:
    'pk_test_51NAycLKkGy6YYt11fCS6MBXw7gLgkN9yNeX0sVhivlTxpcjePFmzFpt4oxWg4tsYoSwmoW1FAZOYRP2Stu9kq1Ce003A8SVTOR',
  //
  sentryKey: 'https://777a8b75ba32423aa85aa894817082e2@sentry.peer.inc/8',
  //
  baseUrl: 'https://api.peerclub.com',
  //
  api: {
    authUrl: 'https://swap.peerclub.com/api/v2/barong',
    tradeUrl: 'https://swap.peerclub.com/api/v2/peatio',
    applogicUrl: 'https://swap.peerclub.com/api/v2/applogic',
    rangerUrl: 'wss://swap.peerclub.com/api/v2/ranger',
    url: 'https://swap.peerclub.com/api/v2',
    jumioUrl: 'https://lon.netverify.com/api',
    aliasUrl: 'https://alias.peerclub.com/api',
  },
}
export default env
