import { useEffect } from 'react'

function useDisableScroll(hide: boolean): void {
  useEffect(() => {
    if (hide) {
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
      document.documentElement.style.overflow = 'unset'
    }
  }, [hide])
}

export default useDisableScroll
