import { IEnv } from './types'

const env: IEnv = {
  title: 'Playa',
  minutesUntilAutoLogout: '60',
  withCredentials: true,
  defaultStorageLimit: 50,
  chainid: 1,
  defaultCurrency: 'vne',
  defaultCurrencyName: 'Vine',
  googleKey: 'G-5WJ1JL36P9',
  termsOfUseVersion: 1,
  walletConnectProjectID: 'cc599461ce1a4bc91dbcf975405794cf',
  refid: 'ID6BE1887B18',
  removePeer: 'true',
  //
  captcha: {
    captchaType: 'turnstile',
    siteKey: '0x4AAAAAAADQoMhQrjV710_v',
    key: '0x4AAAAAAADQoMhQrjV710_v',
  },
  //
  stripeKey:
    'pk_live_51NAycLKkGy6YYt11bIKEpFAtAOBE7lx9nZvdZfFGEHzTk3sOqPIr0rRKpKOHIT0zETlGM3TBjoGhUEbQ1PWVodSi00JhjBzxBi',
  //
  peerclubUrl: 'https://pc.peerclub.com',
  //
  sentryKey: 'https://bbed85fb6a8e4b7595ce07e863194bbb@sentry.peer.inc/6',
  //
  baseUrl: 'https://api.peerclub.com',
  //
  api: {
    authUrl: 'https://api.playa.net/api/v2/barong',
    tradeUrl: 'https://api.playa.net/api/v2/peatio',
    applogicUrl: 'https://api.playa.net/api/v2/applogic',
    rangerUrl: 'wss://api.playa.net/api/v2/ranger',
    url: 'https://api.playa.net/api/v2',
    jumioUrl: 'https://lon.netverify.com/api',
    aliasUrl: 'https://alias.peerclub.com/api',
  },
}
export default env
