import { TNullable } from 'types/utilityTypes'

type TMediaItem = { url: string }
type TMediaItemKeys = 'url' | 'large' | 'medium' | 'small'
type TMediaItemValue<K> = K extends 'url' ? string : TMediaItem

export enum TokenGroup {
  sft = 'sft',
  premium = 'premium',
}

export enum ContractType {
  sft = '1155',
  premium = '721',
}

export type TEmojiMedia = { [K in TMediaItemKeys]: TMediaItemValue<K> }

export type TOrderDataToken = {
  category_name: string
  code: string
  media: TEmojiMedia
  name: string
  price_usd?: string
  price_peer?: string
}

export type TEmojiItem = {
  aasm_state: string
  balance?: TNullable<number>
  category_name: string
  code: string
  contract_address?: TNullable<string>
  created_at?: string
  currency?: string
  description?: string
  fees?: string
  fees_type?: string
  kind: string
  media: TEmojiMedia
  minting_type?: TNullable<string>
  name: string
  origin_volume?: number
  position?: number
  price_peer?: string
  price_usd: string
  purchase_limit?: number
  rarity?: string
  token_group: keyof typeof TokenGroup | string
  token_tags?: unknown[]
  token_type: string
  top_seller?: number
  trending: number
  unicode?: string
  updated_at?: string
  volume?: number
  token_id: string
  token?: TOrderDataToken
  app_forbidden: boolean
}

export type TEmojiItemPurchased = {
  balance: string
  locked: string
  token: TEmojiItem
  contract_address?: string
}
