import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'rc-table'
import { DefaultRecordType } from 'rc-table/lib/interface'
import isEqual from 'react-fast-compare'

import Table from '@components/UiKit/Table'
import Pagination from '@components/UiKit/Pagination'

import Size, { IOption } from '@components/UiKit/Size'
import { settings } from '@constants/settings'

import TransactionsHeader from './Header'
import styles from './style.module.scss'
import { ITransactionTypes, ITtransactionTabItem } from './types'

const { defaultValue: defaultSize } = settings.size

export interface PropsTransactions {
  transactionType: ITransactionTypes
  onChangeTab: (item: ITtransactionTabItem) => void
  getTransaction: () => void
  mappedColumns: Record<string, ColumnsType<DefaultRecordType>>
  items: Record<string, any>[]
  loading?: boolean
  total?: number
  limit?: number
  handlePageChange?: (currentPage: { selected: number }) => void
  page?: number
  handleSizeChange?: (size: IOption) => void
}

function Transactions({
  transactionType,
  onChangeTab,
  getTransaction,
  mappedColumns,
  items,
  loading,
  total,
  limit,
  handlePageChange,
  page,
  handleSizeChange,
}: Readonly<PropsTransactions>) {
  const { t } = useTranslation('nsUserProfile')

  return (
    <div className={styles.container}>
      <TransactionsHeader
        transactionType={transactionType}
        onChangeTab={onChangeTab}
        reload={getTransaction}
      />
      <Table
        displayedMobileCards
        columns={mappedColumns[transactionType]}
        data={items}
        loading={loading}
        emptyText={t('noTransactions')}
      />

      {(total ?? 0) > defaultSize && (
        <div className={styles.footer}>
          <Pagination
            renderOnOnePageCount={limit !== defaultSize}
            itemsPerPage={limit}
            total={total}
            onPageChange={handlePageChange}
            forcePage={page}
            className={styles.pagination}
          />
          <Size total={total} onChange={handleSizeChange} />
        </div>
      )}
    </div>
  )
}

export default memo(Transactions, isEqual)
