import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { isDevEnvironment } from '@constants/index'

import { getResources } from './resources'

export const i18nextInit = (isWithKey) => {
  const resource = getResources(isWithKey)

  i18next.use(initReactI18next).init({
    lng: 'en',
    debug: isDevEnvironment,
    resources: resource,
  })
}

i18nextInit(false)
