import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { isDev } from '@constants/environments'
import { configSelectors } from '@core/redux/selectors'
import routes from '@routing/path'
import { AuthPages } from '@containers/Auth/types'
import {
  fetchSendSessionOTPFailure,
  fetchSendSessionOTPRequest,
  fetchSendSessionOTPSuccess,
  setAuthStep,
} from '@core/redux/auth/actions'
import { useCaptcha } from '@context/CaptchaProvider'
import { useOtpTimer } from '@context/otpTimer'
import { addAppListener, AppDispatch } from '@core/redux/store'

const showPrivateRouteWhenDev = false

export default function PrivateRoute({ children }: React.PropsWithChildren) {
  const { isAuth } = useSelector(configSelectors.isAuthenticated)
  const additionalUserDataCompleted = useSelector(configSelectors.additionalUserDataCompleted)
  const isPhoneVerified = useSelector(configSelectors.isPhoneVerified)
  const isEmailVerified = useSelector(configSelectors.isEmailVerified)
  const isPhoneExist = useSelector(configSelectors.isPhoneExist)

  const { phone } = useSelector(configSelectors.authUserFields)
  const { captchaValue, reset } = useCaptcha()
  const { startTimer } = useOtpTimer()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const listenerSessionOTPFailure = dispatch(
      addAppListener({
        actionCreator: fetchSendSessionOTPFailure,
        effect: () => {
          reset()
          startTimer()
        },
      }),
    )

    const listenerSessionOTPSuccess = dispatch(
      addAppListener({
        actionCreator: fetchSendSessionOTPSuccess,
        effect: () => {
          reset()
          startTimer()
        },
      }),
    )

    return () => {
      listenerSessionOTPFailure()
      listenerSessionOTPSuccess()
    }
  }, [])

  // NOTE: if not authenticated go to auth
  if (!isAuth && !(showPrivateRouteWhenDev && isDev)) {
    dispatch(setAuthStep(AuthPages.email))
    return <Navigate to={routes.withoutAuth.auth} />
  }

  if (!isPhoneExist) {
    dispatch(setAuthStep(AuthPages.updatePhone))
    return <Navigate to={routes.withoutAuth.auth} />
  }

  // NOTE: if user didn't verify phone go to phoneOtp
  if (!isPhoneVerified && captchaValue) {
    dispatch(
      fetchSendSessionOTPRequest({
        identity: phone,
        captcha_response: captchaValue,
        resend: false,
      }),
    )

    dispatch(setAuthStep(AuthPages.phoneOtp))
    return <Navigate to={routes.withoutAuth.auth} />
  }

  if (!isEmailVerified && captchaValue) {
    dispatch(setAuthStep(AuthPages.updateEmailOtp))
    return <Navigate to={routes.withoutAuth.auth} />
  }

  // NOTE: if user doesn't have additional fields go to userData
  if (!additionalUserDataCompleted) {
    if (isEmailVerified) {
      dispatch(setAuthStep(AuthPages.userData))
      return <Navigate to={routes.withoutAuth.auth} state={AuthPages.userData} />
    }

    dispatch(setAuthStep(AuthPages.updateEmailOtp))
    return <Navigate to={routes.withoutAuth.auth} />
  }

  return children as React.JSX.Element
}
