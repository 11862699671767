import { all, call, delay, put, takeLatest, takeLeading, take } from 'typed-redux-saga'
import { toast } from 'react-toastify'

import { API, BarongConfig } from '@core/api'
import { API_URLS } from '@core/api/apiUrls'
import { errorsHandler, getErrorText } from '@core/helper'
import { fetchProfile, fetchProfileSuccess } from '@core/redux/profile/actions'
import { AuthEvents, eventTrack } from '@utils/analyticsTracker'
import { successMessages } from '@constants/messages'
import { Action } from '@reduxjs/toolkit'
import { IUserModel } from '@core/api/types'
import { AuthPages } from '@containers/Auth/types'
import {
  fetchCheckIfUsernameAvailableFailure,
  fetchCheckIfUsernameAvailableRequest,
  fetchCheckIfUsernameAvailableSuccess,
  fetchCreateNewUserOTP,
  fetchCreateNewUserOTPFailure,
  fetchCreateNewUserOTPSuccess,
  fetchSendSessionOTPFailure,
  fetchSendSessionOTPRequest,
  fetchSendSessionOTPSuccess,
  fetchUpdatePhone,
  fetchUpdatePhoneFailure,
  fetchUpdatePhoneSuccess,
  fetchVerifyEmailUpdateFailure,
  fetchVerifyEmailUpdateRequest,
  fetchVerifyEmailUpdateSuccess,
  fetchVerifySignup,
  fetchVerifySignupFailure,
  fetchVerifySignupSuccess,
  goForwardToAuthStep,
  resetCaptcha,
  setAuthFields,
  setAuthLoading,
  verifyPhoneFailure,
  verifyPhoneRequest,
  verifyPhoneSuccess,
  verifySigninEmail,
  verifySigninEmailFailure,
  verifySigninEmailSuccess,
  verifySigninPhoneAndUpdateEmail,
  verifySigninPhoneAndUpdateEmailFailure,
  verifySigninPhoneAndUpdateEmailSuccess,
  verifySignupEmailFailure,
  verifySignupEmailRequest,
  verifySignupEmailSuccess,
  verifyUpdatePhoneFailure,
  verifyUpdatePhoneRequest,
  verifyUpdatePhoneSuccess,
} from './actions'
import { AUTH_TYPES } from './types'

const MS_BEFORE_REDIRECT = 1500

function* resetAndGetNewCaptcha() {
  yield put(resetCaptcha())

  const { payload: newCaptcha } = yield take(AUTH_TYPES.SET_CAPTCHA)

  return newCaptcha
}

function* checkIfUsernameAvailable(action: Action) {
  if (fetchCheckIfUsernameAvailableRequest.match(action)) {
    try {
      yield call(
        API.get(BarongConfig),
        `${API_URLS.identity.users.username}?username=${action.payload.userName}`,
      )
      yield put(fetchCheckIfUsernameAvailableSuccess())
    } catch (err) {
      yield put(fetchCheckIfUsernameAvailableFailure())
    }
  }
}

function* sendSessionOTPSaga(action: Action) {
  if (fetchSendSessionOTPRequest.match(action)) {
    try {
      yield* call(API.post(BarongConfig), API_URLS.identity.sessions.new, action.payload)
      yield* put(fetchSendSessionOTPSuccess())
    } catch (err) {
      yield* put(fetchSendSessionOTPFailure(err))
      errorsHandler(err)
    }
  }
}

function* newUserSendOTPs(action: Action) {
  if (fetchCreateNewUserOTP.match(action)) {
    const { startTimer, ...payload } = action.payload
    try {
      yield* put(setAuthLoading(true))

      eventTrack(AuthEvents.signupBegin())
      startTimer()

      yield* call(API.post<void>(BarongConfig), API_URLS.identity.users.newUser, payload)
      yield* delay(MS_BEFORE_REDIRECT)

      yield* put(fetchCreateNewUserOTPSuccess())
    } catch (err) {
      yield* put(fetchCreateNewUserOTPFailure({ err, requestPayload: payload }))
    } finally {
      yield* put(setAuthFields({ phone: payload.phone_number }))
      yield* put(setAuthLoading(false))
    }
  }
}

function* verifySignupSaga(action: Action) {
  if (fetchVerifySignup.match(action)) {
    try {
      yield* put(setAuthLoading(true))
      yield* call(API.post<void>(BarongConfig), API_URLS.identity.sessions.new, action.payload)
      yield* put(fetchVerifySignupSuccess())
    } catch (err) {
      if (err.message?.[0] === 'identity.session.invalid_params') {
        yield* call([toast, 'info'], getErrorText('identity.user.doesnt_exist'))
        yield* delay(MS_BEFORE_REDIRECT)

        yield* put(fetchVerifySignupFailure())
      }
    } finally {
      yield* put(setAuthLoading(false))
      yield* put(setAuthFields({ email: action.payload.identity }))
    }
  }
}

function* verifySigninEmailSaga(action: Action) {
  if (verifySigninEmail.match(action)) {
    try {
      const userData = yield* call(
        API.post<IUserModel>(BarongConfig),
        API_URLS.identity.sessions.verify.otp,
        action.payload,
      )

      yield* put(verifySigninEmailSuccess({ userData, requestPayload: action.payload }))
      // NOTE: fetchProfileSuccess need to successful signin
      yield put(fetchProfileSuccess(userData))
      // NOTE: fetchProfile need to grab all the user data
      yield* put(fetchProfile())
    } catch (err) {
      yield put(verifySigninEmailFailure(err))
    }
  }
}

function* verifySigninPhoneAndUpdateEmailSaga(action: Action) {
  if (verifySigninPhoneAndUpdateEmail.match(action)) {
    try {
      yield call(API.post<void>(BarongConfig), API_URLS.identity.sessions.verify.otp, {
        identity: action.payload.identity,
        verification_code: action.payload.verification_code,
        platform: action.payload.platform,
        captchaValue: action.payload.captchaValue,
      })

      yield* put(verifySigninPhoneAndUpdateEmailSuccess())
      yield* put(fetchProfile())

      const newCaptchaValue = yield call(resetAndGetNewCaptcha)

      yield* call(API.post<void>(BarongConfig), API_URLS.resource.users.updateEmail, {
        email: action.payload.email,
        platform: action.payload.platform,
        captchaValue: newCaptchaValue,
      })
      yield put(goForwardToAuthStep(AuthPages.updateEmailOtp))
    } catch (err) {
      yield* put(verifySigninPhoneAndUpdateEmailFailure(err))
    }
  }
}

function* verifySignupEmailSaga(action: Action) {
  if (verifySignupEmailRequest.match(action)) {
    try {
      const userData = yield* call(
        API.post<{ phone_number: string; full_name: string; username: string; dob: string }>(
          BarongConfig,
        ),
        API_URLS.identity.sessions.verify.otp,
        action.payload,
      )

      yield* put(verifySignupEmailSuccess(userData))
      yield* put(fetchProfile())
    } catch (err) {
      yield* put(verifySignupEmailFailure(err))
    }
  }
}

function* verifyPhoneSaga(action: Action) {
  if (verifyPhoneRequest.match(action)) {
    try {
      const userData = yield call(
        API.post<{
          phone_number: string
          full_name: string
          username: string
          dob: string
        }>(BarongConfig),
        API_URLS.identity.sessions.verify.otp,
        action.payload,
      )

      yield put(verifyPhoneSuccess(userData))
      yield put(fetchProfileSuccess(userData))
    } catch (err) {
      yield put(verifyPhoneFailure(err))
    }
  }
}

function* updatePhoneOtpSaga(action: Action) {
  if (fetchUpdatePhone.match(action)) {
    try {
      yield* put(setAuthLoading(true))
      yield* call(
        API.post<void>(BarongConfig),
        API_URLS.resource.users.updatePhoneNumber,
        action.payload,
      )
      yield* put(fetchUpdatePhoneSuccess())
    } catch (err) {
      errorsHandler(err)
      yield* put(fetchUpdatePhoneFailure(err))
    } finally {
      yield put(setAuthFields({ phone: action.payload.phone_number }))
      yield put(setAuthLoading(false))
    }
  }
}

function* verifyUpdatePhoneSaga(action: Action) {
  if (verifyUpdatePhoneRequest.match(action)) {
    try {
      const userData = yield call(
        API.post<IUserModel>(BarongConfig),
        API_URLS.resource.users.verifyPhoneNumber,
        action.payload,
      )

      yield put(fetchProfileSuccess(userData))
      yield put(verifyUpdatePhoneSuccess(userData))
    } catch (err) {
      yield put(verifyUpdatePhoneFailure(err))
    }
  }
}

function* sendUserOTPSaga() {
  try {
    yield* put(setAuthLoading(true))
    yield* call(API.post(BarongConfig), API_URLS.resource.users.resendOtp)
  } catch (err) {
    // TODO: add proper handling errors
  } finally {
    yield* put(setAuthLoading(false))
  }
}

function* verifyEmailUpdateSaga(action: Action) {
  if (fetchVerifyEmailUpdateRequest.match(action)) {
    try {
      const userData = yield* call(
        API.post<IUserModel>(BarongConfig),
        API_URLS.resource.users.verifyEmail,
        action.payload,
      )
      yield* put(fetchProfile())
      yield* put(fetchVerifyEmailUpdateSuccess(userData))
      toast.success(successMessages.other.email_updated)
    } catch (err) {
      errorsHandler(err)
      yield* put(fetchVerifyEmailUpdateFailure(err))
    }
  }
}

export function* rootSagaAuth() {
  yield all([
    takeLatest(AUTH_TYPES.FETCH_CHECK_USERNAME_REQUSET, checkIfUsernameAvailable),
    takeLeading(AUTH_TYPES.FETCH_SEND_SESSION_OTP_REQUEST, sendSessionOTPSaga),
    takeLatest(AUTH_TYPES.FETCH_VERIFY_SIGNUP_REQUEST, verifySignupSaga),
    takeLatest(AUTH_TYPES.VERIFY_SIGNIN_EMAIL_OTP_REQUEST, verifySigninEmailSaga),
    takeLatest(AUTH_TYPES.VERIFY_SIGNUP_EMAIL_OTP_REQUEST, verifySignupEmailSaga),
    takeLatest(AUTH_TYPES.VERIFY_PHONE_OTP_REQUEST, verifyPhoneSaga),
    takeLatest(AUTH_TYPES.FETCH_CREATE_NEW_USER_OTP_REQUEST, newUserSendOTPs),
    takeLatest(AUTH_TYPES.FETCH_UPDATE_PHONE_REQUEST, updatePhoneOtpSaga),
    takeLatest(AUTH_TYPES.VERIFY_UPDATE_PHONE_OTP_REQUEST, verifyUpdatePhoneSaga),
    takeLatest(AUTH_TYPES.SEND_USER_OTP_REQUEST, sendUserOTPSaga),
    takeLatest(AUTH_TYPES.FETCH_VERIFY_EMAIL_UPDATE_REQUEST, verifyEmailUpdateSaga),
    takeLatest(
      AUTH_TYPES.VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL,
      verifySigninPhoneAndUpdateEmailSaga,
    ),
  ])
}
