import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pause } from '@phosphor-icons/react'
import { ContractType, TEmojiMedia, TokenGroup } from '@core/redux/emojis/types'

import useCurrentWidth from '@hooks/useCurrentWidth'
import { fixedArgClassNames } from '@utils/utils'
import { TSize } from '@components/UiKit/types'
import { TCardOpenedProps } from './types'
import styles from './styles.module.scss'
import IconButton from '../ButtonNew/IconButton'
import Typography from '../Typography'
import ProgressBar from '../ProgressBar'
import DescriptionItem from './DescriptionItem'
import Button from '../ButtonNew/Button'

function CardOpened({
  owned,
  itemData,
  extraContent,
  onCLose,
  actionBtn,
  showFullScreen,
  showControls,
  size,
  extraDescriptionItems = [],
}: Readonly<TCardOpenedProps>) {
  const { size: autoSize } = useCurrentWidth()
  const currentSize = size || autoSize
  const partionedClassNames = fixedArgClassNames(styles[currentSize])
  const [isBlur, setIsBlur] = useState(true)
  const {
    media = {} as TEmojiMedia,
    name = '',
    price_usd = '',
    token_group,
    token_id,
  } = itemData || {}

  const { t } = useTranslation('nsMoji')
  const card = t('card', { returnObjects: true })

  const emojiImage = isBlur ? media?.medium?.url : media?.large?.url

  const contractType = token_group === TokenGroup.sft ? ContractType.sft : ContractType.premium

  useEffect(() => {
    if (media?.large?.url) {
      setIsBlur(true)
      const tempImage = new Image()
      tempImage.src = media?.large?.url

      tempImage.onload = () => {
        setTimeout(() => setIsBlur(false), 100)
      }
    }
  }, [media?.large?.url])

  return (
    <div className={partionedClassNames(styles.container)} data-testid="card-details">
      <IconButton
        icon="CustomIconClose"
        className={styles.topRightIcon}
        variant="secondary"
        onClick={onCLose}
      />

      <div className={partionedClassNames(styles.preview)}>
        {showFullScreen && (
          <IconButton
            icon="CornersOut"
            className={partionedClassNames(styles.topRightIcon)}
            variant="secondary"
          />
        )}

        <div className={partionedClassNames({ [styles.loading]: isBlur })}>
          <img src={emojiImage} alt="" />
        </div>
        {showControls && (
          <div className={styles.playControls}>
            <div className={partionedClassNames(styles.pauseEllipse)}>
              <Pause weight="fill" size={13} color="#fff" />
            </div>
            <ProgressBar />
            <ProgressBar />
            <ProgressBar progressPercent={35} />
          </div>
        )}
      </div>
      <div className={partionedClassNames(styles.description)}>
        <div className={partionedClassNames(styles.descriptionInner)}>
          <div className={partionedClassNames(styles.minted_label)}>
            <div className={partionedClassNames(styles.minted_label__inner)}>
              <span className={styles.tag}>{card?.vine}</span>
              {owned && (
                <span className={styles.owned}>
                  <span>{card?.owned}</span>
                </span>
              )}
            </div>

            {token_id && (
              <span className={styles.token_id_text}>
                {card?.token} {token_id}
              </span>
            )}
          </div>

          <div className={partionedClassNames(styles.title)}>
            <Typography.Display className={styles.title__text} size={2}>
              {name}
            </Typography.Display>
          </div>

          <Typography.Paragraph className={partionedClassNames(styles.price)} size={1}>
            ${Number(price_usd)}
          </Typography.Paragraph>

          <Typography.Paragraph
            className={partionedClassNames(styles.token_group)}
            font="IBMPlexMono"
            size={4}
          >
            {contractType}
          </Typography.Paragraph>

          <div className={partionedClassNames(styles.buttonsContainer)}>
            {actionBtn && (
              <Button
                fullWidth={currentSize === TSize.small}
                onClick={actionBtn?.onClick}
                variant={actionBtn?.variant}
                loading={!!actionBtn?.loading}
              >
                {actionBtn?.children}
              </Button>
            )}

            {extraContent}
          </div>

          <div className={partionedClassNames(styles.itemsContainer)}>
            {extraDescriptionItems.map((item, index) => (
              <DescriptionItem
                key={item.value}
                isLast={index === extraDescriptionItems.length - 1}
                title={item.title}
                value={item.value}
                size={currentSize}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardOpened
