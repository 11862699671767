import React, { toast } from 'react-toastify'
import copy from 'copy-to-clipboard/index'

import { API_URLS } from '@core/api/apiUrls'
import helpers from '@utils/helpers'
import RobotImg from '@assets/images/landingNav/Robot.png'
import Pill from '@components/UiKit/Pill'
import { TCurrencies, TCurrencyPrecision } from 'types/currencies'
import { IToken } from 'types/common'
import { defaultCurrencyId } from '@core/api/config'
import { TNullable } from 'types/utilityTypes'

import { IconCopy3 } from '@components/Icons/Copy'
import { TEmojiMedia } from '@core/redux/emojis/types'
import { ITtransactionTabItem, ITransactionTypes } from './types'
import styles from './style.module.scss'

export const copyAndNotify = (text: string) => {
  copy(text)
  toast.success('Copied!')
}

export const SUCCESS_STATUSES: Record<string, string> = {
  succeed: 'succeed',
  'payment confirmed': 'payment confirmed',
  completed: 'completed',
  refunded: 'refunded',
  accepted: 'accepted',
  'agreement verified': 'agreement verified',
  prepared: 'prepared',
  submitted: 'submitted',
  mint_completed: 'mint_completed',
}

export const ERROR_STATUSES: Record<string, string> = {
  cancelled: 'cancelled',
  'card declined': 'card declined',
  declined: 'declined',
  failed: 'failed',
  rejected: 'rejected',
  expired: 'expired',
}

export const statusDictionary: Record<string, Record<string, string>> = {
  'NFE Purchase': {
    initiated: 'Prepared',
    deposit_initiated: 'Processing',
    collected: 'Succeed',
    declined: 'Failed',
    mint_completed: 'Mint Completed',
  },

  'Banxa Transaction': {
    pending: 'Payment Pending',
    submitted: 'Payment Confirming',
    accepted: 'Payment Confirmed',
    in_progress: 'Processing',
    transferred: 'Processing',
    deposit_initiated: 'Processing',
    cancelled: 'Cancelled',
    expired: 'Failed',
    declined: 'Card Declined',
    completed: 'Completed',
    refunded: 'Refunded',
  },

  Deposit: {
    submitted: 'Submitted',
    canceled: 'Cancelled',
    rejected: 'Rejected',
    accepted: 'Accepted',
    processing: 'Succeed',
    skipped: '  Succeed',
    collected: 'Succeed',
    fee_processing: 'Succeed',
    errored: 'Succeed',
  },

  Withdraw: {
    prepared: 'Submitted',
    rejected: 'Rejected',
    accepted: 'Accepted',
    skipped: 'Pending',
    processing: 'Processing',
    succeed: 'Succeed',
    canceled: 'Cancelled',
    failed: 'Failed',
    errored: 'Failed',
    confirming: 'Processing',
  },

  Quotes: {
    initiated: 'Submitted',
    agreement_verified: 'Agreement Verified',
    payment: 'Payment Window',
    deposit_initiated: 'Processing',
    completed: 'Succeed',
    declined: 'Failed',
    waiting_for_deposit: 'Waiting for deposit',
  },

  Quote: {
    initiated: 'Submitted',
    agreement_verified: 'Agreement Verified',
    payment: 'Payment Window',
    deposit_initiated: 'Processing',
    completed: 'Succeed',
    declined: 'Failed',
    waiting_for_deposit: 'Waiting for deposit',
  },

  Swap: {
    prepared: 'Prepared',
    submitted: 'Submitted',
    pending: 'Pending',
    canceled: 'Cancelled',
    rejected: 'Rejected',
    collected: 'Succeed',
  },
}

export const getTransactionList = (transactions: any): ITtransactionTabItem[] => [
  {
    type: ITransactionTypes.recentActivities,
    text: transactions?.recentActivities,
    isActive: true,
  },
  {
    type: ITransactionTypes.nfe,
    text: transactions?.nfe,
    isActive: true,
  },
  {
    type: ITransactionTypes.wonkabots,
    text: transactions?.wonkabots,
    isActive: true,
  },
  {
    type: ITransactionTypes.cryptoTX,
    text: transactions?.cryptoTX,
    isActive: true,
  },
  {
    type: ITransactionTypes.quote,
    text: transactions?.quote,
    isActive: true,
  },
  {
    type: ITransactionTypes.withdraw,
    text: transactions?.withdrawals,
    isActive: true,
  },
  {
    type: ITransactionTypes.bonus,
    text: transactions?.bonus,
    isActive: true,
  },
  {
    type: ITransactionTypes.purchase,
    text: transactions?.cctx,
    isActive: false,
  },
]

export const TRANSACTION_URLS_BY_TYPE = {
  [ITransactionTypes.recentActivities]: API_URLS.account.transactions.dashboard,
  [ITransactionTypes.cryptoTX]: API_URLS.account.transactions.adjustments,
  [ITransactionTypes.quote]: API_URLS.account.transactions.quotes,
  [ITransactionTypes.withdraw]: API_URLS.account.transactions.withdraws,
  [ITransactionTypes.purchase]: API_URLS.account.transactions.banxa,
  [ITransactionTypes.bonus]: API_URLS.account.transactions.bonus,
  [ITransactionTypes.nfe]: API_URLS.token.purchase,
  [ITransactionTypes.wonkabots]: API_URLS.wonka.listTokens,
}

export const createNfePrice = ({
  price,
  currency,
  payment_type,
  price_in_usd,
  currencyPrecision,
}: {
  payment_type: string
  price: string
  price_in_usd: string
  currency: string
  currencyPrecision: any
}) => {
  let currentPrice = price || 0
  let currentCurrency = currency || ''

  if (payment_type === 'stripe' || payment_type === 'banxa') {
    currentPrice = price_in_usd
    currentCurrency = 'USD'
  }

  return (
    `${helpers.truncateData(
      currentPrice,
      currencyPrecision?.[currency],
    )} ${currentCurrency.toUpperCase()}` || '-'
  )
}

type TVariant = 'success' | 'error' | 'warning'
type TStatus = { text: string; variant: TVariant }

export const getStatus = (type: string, status: string): TStatus => {
  const obj: TStatus = {
    text: '',
    variant: 'warning',
  }

  if (!status) {
    return obj
  }

  const foundStatus = statusDictionary[type]?.[status]

  if (foundStatus) {
    obj.text = foundStatus
  } else {
    const joinedStatus = status.split('_').join(' ')
    obj.text = helpers.capitalize(joinedStatus)
  }
  const statusKey = obj.text.toLowerCase()

  const isError = ERROR_STATUSES[statusKey] || ERROR_STATUSES[status]
  const isSuccess = SUCCESS_STATUSES[statusKey] || SUCCESS_STATUSES[status]

  if (isSuccess) {
    obj.variant = 'success'
  }

  if (isError) {
    obj.variant = 'error'
  }

  return obj
}

function TransactionCode({
  code,
  withCopy = true,
}: Readonly<{
  code: string
  withCopy?: boolean
}>): JSX.Element {
  const textCode = helpers.getTransactionId(code)

  return (
    <div className={styles.code} title={code}>
      {textCode || '-'}
      {withCopy && textCode && (
        <button
          data-testid="button-copy"
          className={styles.btnCopy}
          onClick={() => {
            copyAndNotify(code)
          }}
          type="button"
        >
          <IconCopy3 color="currentColor" />
        </button>
      )}
    </div>
  )
}

function TransactionLink({
  url,
  code,
  linkCode,
  withCopy,
}: Readonly<{
  url?: string
  linkCode?: string
  code?: string
  withCopy?: boolean
}>): TNullable<JSX.Element> {
  if (url) {
    return (
      <a target="_blank" href={url} rel="noreferrer">
        <TransactionCode code={linkCode || code || ''} withCopy={false} />
      </a>
    )
  }

  if (code) {
    return <TransactionCode code={code} withCopy={withCopy} />
  }

  return null
}

const explorerTransactionUrl = (
  currencies: TCurrencies,
  currency_id: string,
  txid: string,
): string => {
  let url = ''
  const currency = currencies.find((item) => item.id === currency_id)
  const explorerTransaction = currency?.explorer_transaction
  if (explorerTransaction !== undefined) {
    url = explorerTransaction.replace('#{txid}', txid)
  }
  return url
}

const explorerAddressUrl = (
  currencies: TCurrencies,
  currency_id: string,
  address: string,
): string => {
  let url = ''
  const currency = currencies.find((item) => item.id === currency_id)
  const explorerAddress = currency?.explorer_address
  if (explorerAddress !== undefined) {
    url = explorerAddress.replace('#{address}', address)
  }
  return url
}

const commonCell = {
  status: ({
    type,
    statusKey = 'aasm_state',
    typeKey,
    title = '',
  }: {
    type?: string
    statusKey?: string
    typeKey?: string
    title?: string
  }) => ({
    title,
    dataIndex: '',
    key: 'status',
    width: 190,
    render: (item: Record<string, string>) => {
      const { text, variant } = getStatus(
        type ?? ((typeKey && item[typeKey]) || ''),
        item[statusKey]?.toLowerCase(),
      )

      return text ? (
        <Pill className={styles.status} variant={variant}>
          {text}
        </Pill>
      ) : (
        '-'
      )
    },
  }),
  date: ({ key = 'created_at', title = '' } = {}) => ({
    title,
    dataIndex: key,
    key,
    render: (item) => helpers.convertDate(item) || '-',
  }),
  mintTxId: ({
    currencies,
    title = '',
  }: {
    currencies: TNullable<TCurrencies>
    title?: string
  }) => ({
    title,
    dataIndex: '',
    key: 'mint_txid',
    render: ({ mint_txid }: { mint_txid: string }) => {
      const url =
        currencies && mint_txid && explorerTransactionUrl(currencies, defaultCurrencyId, mint_txid)

      if (!url) {
        return '-'
      }

      return <TransactionLink url={url} linkCode={mint_txid} />
    },
  }),
}

export const getRecentTransactionColumns = (
  currencyPrecision: TCurrencyPrecision,
  currencies: TCurrencies,
  columnsNames,
) => [
  {
    title: columnsNames.id,
    dataIndex: '',
    key: 'transaction_id',
    render: ({ currency_id, tid, txid }: { currency_id: string; tid: string; txid: string }) => {
      const url = currencies && txid && explorerTransactionUrl(currencies, currency_id, txid)

      return <TransactionLink url={url} linkCode={txid} code={tid || txid} />
    },
  },
  {
    title: columnsNames.type,
    dataIndex: 'type',
    key: 'transaction_type',
  },
  {
    title: columnsNames.item,
    dataIndex: '',
    key: 'item',
    render: ({ amount, currency_id, token }) =>
      `${helpers.truncateData(amount || 0, currencyPrecision?.[currency_id] || 2)} ${(
        token || currency_id
      )?.toUpperCase()}`,
  },
  commonCell.status({ typeKey: 'type', title: columnsNames.status }),
  commonCell.date({ title: columnsNames.date }),
]

export const getCryptoTXTransactionColumns = (
  currencyPrecision: TCurrencyPrecision,
  columnsNames,
) => [
  {
    title: columnsNames.id,
    dataIndex: 'transaction_id',
    key: 'transaction_id',
    width: 200,
    render: (code) => <TransactionCode code={code} />,
  },
  {
    title: columnsNames.sourceCoinPrice,
    dataIndex: 'source_coin_price',
    key: 'source_coin_price',
    width: 200,
    render: (item) => `$ ${helpers.truncateData(item || 0, 2)}`,
  },
  {
    title: columnsNames.sourceCoinAmount,
    dataIndex: '',
    key: 'Amount',
    width: 250,
    render: ({ source_coin_amount, source_coin }) =>
      `${helpers.truncateData(
        source_coin_amount || 0,
        currencyPrecision?.[source_coin],
      )} ${source_coin?.toUpperCase()}`,
  },
  {
    title: columnsNames.destinationCoinPrice,
    dataIndex: 'destination_coin_price',
    key: 'destination_coin_price',
    width: 250,
    render: (item) => `$ ${helpers.truncateData(item || 0, 2)}`,
  },
  {
    title: columnsNames.destinationCoinAmount,
    dataIndex: '',
    key: 'destination_coin_amount',
    width: 300,
    render: ({ destination_coin_amount, destination_coin }) =>
      `${helpers.truncateData(destination_coin_amount || 0, 2)} ${destination_coin?.toUpperCase()}`,
  },
  commonCell.status({ type: 'Swap', statusKey: 'order_state', title: columnsNames.status }),
  commonCell.date({ title: columnsNames.date }),
]

export const getQuoteTransactionColumns = (currencyPrecision, columnsNames) => [
  {
    title: columnsNames.quoteId,
    dataIndex: 'quote_id',
    key: 'quote_id',
    width: 150,
    render: (code) => <TransactionCode code={code} />,
  },
  {
    title: columnsNames.sourceCoinPrice,
    dataIndex: 'source_coin_price',
    key: 'source_coin_price',
    width: 200,
    render: (item) => `$ ${helpers.truncateData(item || 0, 2)}`,
  },
  {
    title: columnsNames.sourceCoinAmount,
    dataIndex: '',
    key: 'Amount',
    width: 200,
    render: ({ source_coin_amount, source_coin }) =>
      `${helpers.truncateData(
        source_coin_amount || 0,
        currencyPrecision?.[source_coin],
      )} ${source_coin?.toUpperCase()}`,
  },
  {
    title: columnsNames.destinationCoinPrice,
    dataIndex: 'destination_coin_price',
    key: 'destination_coin_price',
    width: 225,
    render: (item) => `$ ${helpers.truncateData(item || 0, 2)}`,
  },
  {
    title: columnsNames.destinationCoinAmount,
    dataIndex: '',
    key: 'destination_coin_amount',
    width: 225,
    render: ({ destination_coin_amount, destination_coin }) =>
      `${helpers.truncateData(destination_coin_amount || 0, 2)} ${destination_coin?.toUpperCase()}`,
  },
  commonCell.status({ type: 'Quotes', statusKey: 'order_state', title: columnsNames.status }),
  commonCell.date({ title: columnsNames.date }),
]

export const getWithdrawsTransactionColumns = (
  currencyPrecision: TCurrencyPrecision,
  currencies: TCurrencies,
  columnsNames,
) => [
  {
    title: columnsNames.id,
    dataIndex: '',
    key: 'transaction_id',
    render: ({ currency, blockchain_txid }) => {
      const url =
        currencies &&
        blockchain_txid &&
        explorerTransactionUrl(currencies, currency, blockchain_txid)

      return (
        <TransactionLink
          url={url}
          linkCode={blockchain_txid}
          code={blockchain_txid}
          withCopy={false}
        />
      )
    },
  },
  {
    title: columnsNames.item,
    dataIndex: '',
    key: 'item',
    render: ({ amount, currency }) =>
      `${helpers.truncateData(
        amount || 0,
        currencyPrecision?.[currency],
      )} ${currency?.toUpperCase()}`,
  },
  {
    title: columnsNames.receiverAddress,
    dataIndex: '',
    key: 'receiver_address',
    render: ({ currency, rid }) => (
      <a
        target="_blank"
        href={(currencies && explorerAddressUrl(currencies, currency, rid)) || '#'}
        rel="noreferrer"
      >
        <TransactionCode code={rid} withCopy={false} />
      </a>
    ),
  },
  {
    title: columnsNames.fee,
    dataIndex: '',
    key: 'item',
    render: ({ fee, currency }) => `${fee}${currency}`,
  },
  commonCell.status({ statusKey: 'state', type: 'Withdraw', title: columnsNames.status }),
  commonCell.date({ title: columnsNames.date }),
]

export const getPurchaseTransactionColumns = (columnsNames) => [
  {
    title: 'Source Amount',
    dataIndex: '',
    key: 'Source_Amount',
    render: ({ source_currency, amount }) =>
      `${helpers.truncateData(amount || 0, 2)} ${source_currency?.toUpperCase() || ''}`,
  },
  {
    title: 'Order ID',
    dataIndex: '',
    key: 'Order_ID',
    render: ({ order_id }: { order_id: string }) => <TransactionCode code={order_id} />,
  },
  commonCell.status({ statusKey: 'state', type: 'Banxa Transaction', title: columnsNames.status }),
  commonCell.date({ title: columnsNames.date }),
  {
    title: columnsNames.id,
    dataIndex: '',
    key: 'Transaction_ID',
    render: ({ tid }: { tid: string }) => <TransactionCode code={tid} />,
  },
]

export const getBonusTransactionColumns = (columnsNames) => [
  {
    title: columnsNames.id,
    dataIndex: 'tid',
    key: 'Transaction_ID',
    render: (code) => <TransactionCode code={code} />,
  },
  {
    title: columnsNames.type,
    dataIndex: 'bonus_type',
    key: 'bonus_type',
    render: (item) => helpers.capitalize(item?.replace(/_/g, ' ')),
  },

  {
    title: columnsNames.bonusReceived,
    dataIndex: '',
    key: 'Bonus_Received',
    render: ({ amount, currency_id }) =>
      `${helpers.truncateData(amount || 0, 2)} ${currency_id?.toUpperCase()}`,
  },
  {
    title: columnsNames.status,
    dataIndex: 'status',
    key: 'status',
    render: (item) =>
      item?.status ? helpers.capitalize(item.status.split('_').join(' ')) : 'Collected',
  },
  commonCell.date({ title: columnsNames.date }),
]

export const getNFETransactionColumns = (
  currencyPrecision: TCurrencyPrecision,
  currencies: TNullable<TCurrencies>,
  columnsNames,
) => [
  {
    title: columnsNames.id,
    dataIndex: '',
    key: 'Txid',
    width: 290,
    render: ({ tid, txid, currency }: { tid: string; txid: string; currency: string }) => {
      const url = currencies && txid && explorerTransactionUrl(currencies, currency, txid)

      if (url) {
        return (
          <a target="_blank" href={url} rel="noreferrer">
            <TransactionCode code={txid} withCopy={false} />
          </a>
        )
      }

      return <TransactionCode code={tid} />
    },
  },
  {
    title: columnsNames.image,
    dataIndex: '',
    key: 'image',
    render: ({ token }: { token: IToken }) => {
      const media = token?.media
      const imgURl = media?.small?.url || media?.medium?.url || media?.url
      const name = token?.name
      return <img data-testid="img" className={styles.nfeImage} src={imgURl} alt={name} />
    },
  },
  {
    title: columnsNames.name,
    dataIndex: '',
    key: 'name',
    render: ({ token }) => token?.name || '',
  },
  {
    title: columnsNames.quantity,
    dataIndex: '',
    key: 'Quantity',
    render: ({ volume, token }: { volume: number; token: IToken }) =>
      `${volume} ${token?.code?.toUpperCase()}`,
  },
  {
    title: columnsNames.price,
    dataIndex: '',
    key: 'currency',
    render: ({
      payment_type,
      price,
      price_in_usd,
      currency,
    }: {
      payment_type: string
      price: string
      price_in_usd: string
      currency: string
    }) =>
      createNfePrice({
        price,
        currency,
        payment_type,
        price_in_usd,
        currencyPrecision,
      }),
  },
  {
    title: columnsNames.paymentType,
    dataIndex: 'payment_type',
    key: 'payment_type',
  },
  commonCell.mintTxId({ currencies, title: columnsNames.mintTxId }),
  commonCell.status({ statusKey: 'aasm_state', type: 'NFE Purchase', title: columnsNames.status }),
  commonCell.date({ title: columnsNames.date }),
]

export const getWonkabotsTransactionColumns = (currencies: TCurrencies, columnsNames) => [
  {
    title: columnsNames.id,
    dataIndex: '',
    key: 'Txid',
    width: 290,
    render: ({ tid, txid, currency }: { tid: string; txid: string; currency: string }) => {
      const url = currencies && txid && explorerTransactionUrl(currencies, currency, txid)

      return <TransactionLink url={url} linkCode={txid} code={tid} />
    },
  },
  {
    title: columnsNames.image,
    dataIndex: '',
    key: 'image',
    render: (data: { media: TEmojiMedia; code: string }) => {
      const media = data?.media
      const imgURl = media?.small?.url || media?.medium?.url || media?.url || RobotImg
      const code = data?.code
      return <img className={styles.nfeImage} src={imgURl} alt={code} />
    },
  },
  {
    title: columnsNames.name,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: columnsNames.code,
    dataIndex: 'code',
    key: 'Code',
  },
  {
    title: columnsNames.price,
    dataIndex: '',
    key: 'currency',
    render: ({ price_usd }: { price_usd: string }) => {
      const currentPrice = price_usd || 0
      const currentCurrency = 'USD'

      const value = `${helpers.truncateData(currentPrice)} ${currentCurrency.toUpperCase()}`

      if (!value.trim()) {
        return '-'
      }

      return value
    },
  },
  {
    title: columnsNames.paymentType,
    dataIndex: 'payment_type',
    key: 'payment_type',
  },
  commonCell.mintTxId({ currencies, title: columnsNames.mintTxId }),
  commonCell.status({ statusKey: 'status', title: columnsNames.status }),
  commonCell.date({ title: columnsNames.date }),
]
