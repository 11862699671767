/* eslint-disable import/no-unresolved */
import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'

import {
  setProfileEmojiPicture,
  setProfileWonkaPicture,
  setProfileExternalNFTPicture,
} from '@core/redux/profile/actions'
import Modal from '@components/UiKit/Modal'
import Typography from '@components/UiKit/Typography'
import Button from '@components/UiKit/ButtonNew/Button'
import MojiCard from '@components/MojiCard'
import NavBar from '@components/UiKit/NavBar'
import { LoaderComponent } from '@components/Loader'
import useDisableScroll from '@hooks/useDisableScroll'
import IconButton from '@components/UiKit/ButtonNew/IconButton'
import { IPlayaTypes, IDataType, IHeadersType } from '@containers/Settings/Modals/PlayaNFTs/types'
import NoDataPurchased from '@components/Profile/NoDataPurchased'
import { CONTRACT_INTERFACES } from '@constants/web3'
import { TEmojiMedia } from '@core/redux/emojis/types'
import { TNullable } from 'types/utilityTypes'
import { useTranslation } from 'react-i18next'
import { isDevOrDevEnvironment } from '@constants/environments'
import { BrowserProvider } from 'ethers'
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import styles from './modal.module.scss'
import { getPageList } from './PlayaSettings'

const { Heading } = Typography

export interface IPlayaTypeProps {
  open: boolean
  onClose: () => void
  isLoadingImages: boolean
  data: IDataType
  fetchData: (type) => void
  headers?: IHeadersType
}

export type TNFTTokenData = {
  contract_type: CONTRACT_INTERFACES
  name: string
  symbol: string
  imageUrl: string
  contract_address: string
  tokenId: string
  volume: number
  token_id: string
  address: string
  media: TEmojiMedia
}

function PlayaNFTs({
  open,
  onClose,
  isLoadingImages,
  data,
  fetchData,
  headers,
}: Readonly<IPlayaTypeProps>) {
  const [imageType, setImageType] = useState<IPlayaTypes>(IPlayaTypes.emoji)
  const [activeCode, setActiveCode] = useState('')
  const [modalScrollBlock, setModalScrollBlock] = useState<TNullable<HTMLDivElement>>(null)
  const [externalEmoji, setExternalEmoji] = useState<TNFTTokenData>()
  const { t } = useTranslation('nsPopupTitles')
  const { t: tSettings } = useTranslation('nsSettings')
  const { t: tCommon } = useTranslation('nsCommon')

  const { walletProvider } = useWeb3ModalProvider()
  const { address } = useWeb3ModalAccount()

  Object.keys(data).forEach((key) => {
    if (key === 'wonka' && data[key] !== undefined) {
      data[key] = data[key]?.filter((item) => item.revealed === true)
    }
  })

  const refIsGetAllData = useRef(false)

  refIsGetAllData.current = (data[imageType]?.length ?? 0) >= Number(headers?.[imageType]?.total)

  const dispatch = useDispatch()
  useDisableScroll(open)

  const onClickCard = useCallback((code) => {
    setActiveCode(code)
  }, [])

  const onSetHandler = useCallback(
    async (token: TNFTTokenData | undefined) => {
      if (!walletProvider || !token) return

      const provider = new BrowserProvider(walletProvider)

      try {
        const signer = await provider.getSigner()
        const payload = {
          address,
          contract_address: token.contract_address,
          contract_type: token.contract_type,
          token_id: token.token_id,
        }
        const signature = await signer?.signMessage(JSON.stringify(payload))
        dispatch(setProfileExternalNFTPicture({ nft_details: payload, signature }))
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }, 100)
      } catch (err) {
        if (isDevOrDevEnvironment) {
          console.log(err)
        }
      }
    },
    [walletProvider],
  )

  const handleSelect = () => {
    const formData = new FormData()

    if (imageType === IPlayaTypes.wonka) {
      formData.append('wonka', activeCode)
      dispatch(setProfileWonkaPicture(formData))
    } else if (imageType === IPlayaTypes.emoji) {
      formData.append('token', activeCode)
      dispatch(setProfileEmojiPicture(formData))
    } else if (imageType === IPlayaTypes.externalEmoji) {
      onSetHandler(externalEmoji)
    }

    onClose()
    setActiveCode('')
    setImageType(IPlayaTypes.emoji)
  }

  const handleClose = useCallback(() => {
    onClose()
    setActiveCode('')
    setTimeout(() => setImageType(IPlayaTypes.emoji), 0)
  }, [])

  const handleCloseWithExtras = useCallback(() => {
    if (onClose) {
      onClose()
    }

    setImageType(IPlayaTypes.emoji)
  }, [onClose])

  const onChangeTab = useCallback(
    (type: IPlayaTypes) => {
      setActiveCode('')
      setImageType(type)
      modalScrollBlock?.scrollTo?.(0, 0)
    },
    [modalScrollBlock],
  )

  useEffect(() => {
    let prevScrollTop = 0
    const handleScroll = () => {
      if (modalScrollBlock) {
        const { scrollTop, clientHeight, scrollHeight } = modalScrollBlock

        if (prevScrollTop > scrollTop || refIsGetAllData.current) return

        if (clientHeight + scrollTop + 500 >= scrollHeight) {
          prevScrollTop = scrollHeight
          fetchData(imageType)
          return
        }

        prevScrollTop = scrollTop
      }
    }

    modalScrollBlock?.addEventListener('scroll', handleScroll)
    return () => {
      modalScrollBlock?.removeEventListener('scroll', handleScroll)
    }
  }, [modalScrollBlock, imageType, fetchData])

  const currentData = data[imageType]
  const currentDataLength = currentData?.length

  const navBarTabs = useMemo(() => {
    const pageList = getPageList({
      mojisTitle: tSettings('internalNfts'),
      externalMojisTitle: tSettings('externalNfts'),
      wonkaTitle: tSettings('wonka'),
    })
    const hasWonka = data[IPlayaTypes.wonka]?.length !== 0
    const hasEmoji = data[IPlayaTypes.emoji]?.length !== 0

    if (!hasWonka && !hasEmoji) {
      setImageType(IPlayaTypes.externalEmoji)
      return pageList.filter((item) => item.key === IPlayaTypes.externalEmoji)
    }

    return pageList
  }, [data])

  const handleSelectItem = (e, itemCode, item) => {
    onClickCard(itemCode)
    setExternalEmoji(item)
  }

  return (
    <Modal onClose={handleCloseWithExtras} open={open} className={cn(styles.playaNFTsWrapper)}>
      <div className={styles.header}>
        <Heading size={3} className={styles.title}>
          {t('playaNfts')}
        </Heading>
        <IconButton
          onClick={handleClose}
          icon="CustomIconClose"
          className={styles.closeBtn}
          variant="secondary"
        />
      </div>

      <NavBar
        isPlayaNFTsModal
        wrapperClassName={styles.navBar}
        fullWidth
        align="middle"
        size="medium"
        items={navBarTabs}
        onSelectTab={onChangeTab}
      />

      {isLoadingImages && <LoaderComponent className={styles.loader} absoluteCenter />}

      <div className={styles.list} ref={(e) => setModalScrollBlock(e)}>
        {(currentDataLength &&
          data[imageType]?.map((item, i) => {
            const { token, name, code, token_id } = item
            const itemName = token?.name || name
            const imgSrc = token?.media?.medium?.url || token?.media?.large?.url
            const imgWonkaSrc = item?.media?.medium?.url || item?.media?.large?.url
            const imgExternalNftSrc = item?.media?.url
            const itemCode = token?.code || code || token_id
            const order = i + 1
            const balance = Number(item?.balance)
            const contractType = item?.contract_type

            return (
              <MojiCard
                style={{
                  order,
                }}
                className={cn(styles.card, { [styles.single_card]: currentDataLength === 1 })}
                key={itemCode}
                name={itemName}
                src={imgSrc || imgWonkaSrc || imgExternalNftSrc}
                onClick={(e) => handleSelectItem(e, itemCode, item)}
                isActive={itemCode === activeCode}
                quantity={balance > 1 ? balance : undefined}
                contractType={contractType}
              />
            )
          })) || <NoDataPurchased />}
      </div>

      <div className={styles.footer}>
        <Button
          type="button"
          variant="secondary"
          className={styles.cancel_button}
          onClick={handleClose}
        >
          {tCommon('cancel')}
        </Button>

        <Button type="button" variant="primary" onClick={handleSelect} disabled={!activeCode}>
          {tCommon('confirmSelected')}
        </Button>
      </div>
    </Modal>
  )
}

export default React.memo(PlayaNFTs)
