import { TCropValue } from './types'

export const getCropImageURl = (image: HTMLImageElement, cropValue: TCropValue) => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = cropValue.width
  canvas.height = cropValue.height
  const ctx = canvas.getContext('2d')

  ctx?.drawImage(
    image,
    cropValue.x * scaleX,
    cropValue.y * scaleY,
    cropValue.width * scaleX,
    cropValue.height * scaleY,
    0,
    0,
    cropValue.width,
    cropValue.height,
  )
  return canvas.toDataURL()
}
