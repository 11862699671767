import ReactGA from 'react-ga4'

import helpers from '@utils/helpers'
import { isDevOrDevEnvironment, isProductionEnvironment } from '@constants/index'
import { ANALYTICS_CATEGORY } from './analyticsTracker.constants'
import PAYMENT_EVENTS from './categories/payment'
import EMOJI_EVENTS from './categories/emoji'
import WONKA_EVENTS from './categories/wonka'
import CHECKOUT_EVENTS from './categories/checkout'
import SEARCH_EVENTS from './categories/search'
import CART_EVENTS from './categories/cart'
import PURCHASE_EVENTS from './categories/purchase'
import AUTH_EVENTS from './categories/auth'
import STRIPE_EVENTS from './categories/stripe'
import WEB3_WALLET_EVENTS from './categories/web3Wallet'

interface LabelOptions {
  label?: string
  initialLabel?: string
  labelReplaceByKeys?: { [key: string]: string }
  labelEnding?: string
}

const prepareLabel = ({
  label,
  initialLabel,
  labelReplaceByKeys,
  labelEnding,
}: LabelOptions): string | undefined => {
  let newLabel = label

  if (!newLabel) {
    if (labelReplaceByKeys) {
      newLabel = initialLabel
      if (newLabel) {
        Object.entries(labelReplaceByKeys).forEach(([key, value]) => {
          newLabel = newLabel?.replace(key, value)
        })
      }
    } else {
      newLabel = initialLabel || ''
    }
  }

  if (labelEnding) {
    newLabel = `${newLabel} ${labelEnding}`
  }

  return newLabel
}

interface EventOptions {
  label?: string
  labelReplaceByKeys?: { [key: string]: string }
  labelEnding?: string
  value?: string
  nonInteraction?: boolean
  paymentType?: string
  currency?: string
  searchingItem?: string
  address?: string
  ownedTokensCount?: number
}

type EventAction = (options?: EventOptions) => object

const prepareActions = (
  category: string,
  events: {
    [key: string]: { event: string; label?: string; value?: string; nonInteraction?: boolean }
  },
): { [key: string]: EventAction } => {
  const actions: { [key: string]: EventAction } = {}
  Object.entries(events).forEach(([item, valueItem]) => {
    actions[item] = ({
      label,
      labelReplaceByKeys,
      labelEnding,
      value,
      nonInteraction,
      paymentType,
      currency,
      searchingItem,
      address,
      ownedTokensCount,
    }: EventOptions = {}) => {
      const newLabel = prepareLabel({
        label,
        initialLabel: valueItem.label,
        labelReplaceByKeys,
        labelEnding,
      })

      const obj = {
        category,
        action: valueItem.event,
        nonInteraction: nonInteraction || valueItem.nonInteraction,
        value: value || valueItem.value,
        label: newLabel,
        payment_type: paymentType,
        currency,
        search_term: searchingItem,
        address,
        nft_tokens_count: ownedTokensCount,
      }

      return helpers.removeEmptyValues(obj)
    }
  })

  return actions
}

export const PaymentEvents = prepareActions(ANALYTICS_CATEGORY.payment, PAYMENT_EVENTS)
export const EmojiEvents = prepareActions(ANALYTICS_CATEGORY.emoji, EMOJI_EVENTS)
export const WonkaEvents = prepareActions(ANALYTICS_CATEGORY.wonka, WONKA_EVENTS)
export const CheckoutEvents = prepareActions(ANALYTICS_CATEGORY.checkout, CHECKOUT_EVENTS)
export const SearchEvents = prepareActions(ANALYTICS_CATEGORY.search, SEARCH_EVENTS)
export const CartEvents = prepareActions(ANALYTICS_CATEGORY.cart, CART_EVENTS)
export const PurchaseEvents = prepareActions(ANALYTICS_CATEGORY.purchase, PURCHASE_EVENTS)
export const AuthEvents = prepareActions(ANALYTICS_CATEGORY.auth, AUTH_EVENTS)
export const StripeEvents = prepareActions(ANALYTICS_CATEGORY.stripe, STRIPE_EVENTS)
export const Web3WalletEvents = prepareActions(ANALYTICS_CATEGORY.web3Wallet, WEB3_WALLET_EVENTS)

export interface EventTrackOptions {
  category?: string
  action?: string
  label?: string
  value?: string
  nonInteraction?: boolean
  [key: string]: any
}

export const eventTrack = ({
  category,
  action,
  label,
  value,
  nonInteraction = false,
  ...rest
}: EventTrackOptions): void => {
  const options = helpers.removeEmptyValues({
    category,
    action,
    label,
    value,
    nonInteraction,
    ...rest,
  })
  if (isDevOrDevEnvironment) {
    // eslint-disable-next-line no-console
    console.table({
      TRACK: 'EVENT',
      _____: '________________________',
      ...options,
    })
  }

  if (isProductionEnvironment && category && action) {
    ReactGA.event(options)
  }
}
