/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import cn from 'classnames'
import LoaderIcon from '@components/Icons/Loader'
import loaderGradient from '@components/Icons/Loader/loaderGradient.svg'

import Typography from '@components/UiKit/Typography'
import styles from './style.module.scss'

const { Label } = Typography
interface PropsLoaderComponent {
  height?: string
  color?: string
  center?: boolean
  absoluteCenter?: boolean
  gradient?: boolean
  labelText?: string
  size?: number
  className?: string
  iconClassName?: string
}

export function LoaderComponent({
  height,
  color,
  center,
  absoluteCenter,
  gradient,
  labelText,
  size = 40,
  className,
  iconClassName,
}: Readonly<PropsLoaderComponent>) {
  return (
    <div
      data-testid="loader"
      style={{ height }}
      className={cn(
        styles.wrap_loader,
        {
          [styles['wrap_loader--center']]: center,
          [styles['wrap_loader--absolute-center']]: absoluteCenter,
        },
        className,
      )}
    >
      {!gradient ? (
        <LoaderIcon color={color} size={size} />
      ) : (
        <div className={cn(styles.gradientIcon, iconClassName)}>
          <img loading="eager" fetchPriority="high" src={loaderGradient} width={40} alt="" />

          <Label className={styles.label} size={5}>
            {labelText}
          </Label>
        </div>
      )}
    </div>
  )
}

interface PropsLoader {
  color?: string
  height?: string
  heightContainer?: string
  loading: boolean
  center?: boolean
  absoluteCenter?: boolean
  isMountContent?: boolean
  isMountVisibleContent?: boolean
  gradient?: boolean
  labelText?: string
  className?: string
  iconClassName?: string
  size?: number
  children: React.ReactNode
}

export default function Loader({
  isMountVisibleContent,
  isMountContent,
  center,
  absoluteCenter,
  color,
  loading,
  children,
  height,
  heightContainer,
  gradient,
  labelText,
  size = 40,
  className,
  iconClassName,
}: Readonly<PropsLoader>) {
  const LoadingIndicatorProps = {
    height,
    center,
    absoluteCenter,
    color,
    gradient,
    labelText,
    size,
    className,
    iconClassName,
  }

  if (isMountContent) {
    return (
      <>
        {loading && <LoaderComponent {...LoadingIndicatorProps} />}
        <div style={{ display: loading ? 'none' : 'block', height: heightContainer }}>
          {children}
        </div>
      </>
    )
  }

  if (isMountVisibleContent) {
    return (
      <div
        style={{
          minHeight: height,
        }}
        className={cn(styles.wrapper, { [styles['wrapper--active']]: loading })}
      >
        {loading && (
          <LoaderComponent
            absoluteCenter
            color={color}
            className={className}
            iconClassName={iconClassName}
          />
        )}
        {children}
      </div>
    )
  }

  if (loading) {
    return <LoaderComponent {...LoadingIndicatorProps} />
  }

  return <>{children}</>
}
