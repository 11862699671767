import React from 'react'

/* eslint-disable max-len */
export function IconCamera() {
  return (
    <svg viewBox="0 0 34 30" xmlns="http://www.w3.org/2000/svg" width="34" height="30">
      <path
        fillRule="evenodd"
        fill="currentColor"
        d="M24.705 0c1.3 0 2.433.893 2.748 2.165l.612 2.467a1.42 1.42 0 0 0 1.374 1.082h.31c2.348 0 4.252 1.919 4.252 4.286v14.286c0 3.156-2.537 4.814-5.667 4.814H5.667c-3.13 0-5.668-1.658-5.668-4.814V10c0-2.367 1.904-4.286 4.252-4.286h.31a1.42 1.42 0 0 0 1.375-1.082l.611-2.467A2.84 2.84 0 0 1 9.296 0h15.409zm-7.706 24.286c3.912 0 7.086-3.198 7.086-7.143S20.911 10 16.999 10c-3.912 0-7.083 3.198-7.083 7.143s3.171 7.143 7.083 7.143z"
      />
    </svg>
  )
}

export function IconTrashCan() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
      <g>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
          <path
            fill="currentColor"
            d="M3797.1,4995.7c-187.9-51.8-371.9-208.9-469.6-402.5c-47.9-93.9-103.5-279.9-128.4-427.4l-23-138H2150.6H1125.1l-55.6-55.6c-70.9-72.8-74.8-157.2-7.7-222.4c42.2-44.1,59.4-47.9,247.3-47.9h201.3l9.6-99.7c5.8-55.6,80.5-1798,164.8-3870c115-2790.9,162.9-3799.1,184-3889.2c61.3-262.6,243.4-488.8,463.9-578.9l109.3-44.1h2558.9h2558.9l109.3,44.1c226.2,92,416,337.4,471.5,607.6c11.5,53.7,90.1,1799.9,176.3,3881.5c84.3,2081.6,159.1,3822.1,164.8,3868.1l9.6,80.5h201.3c187.9,0,205.1,3.8,247.3,47.9c61.3,59.4,61.3,143.8,1.9,214.7l-46,53.7l-1203.8,9.6l-1203.8,9.6l-23,138c-67.1,396.8-235.8,665.1-492.6,784l-118.8,55.6l-977.6,3.8C4071.2,5022.5,3881.5,5018.7,3797.1,4995.7z M5865.4,4637.3c47.9-28.8,109.3-86.3,138-128.4c57.5-88.2,124.6-272.2,141.8-394.9l11.5-86.3H4834.1H3509.6l26.8,134.2c57.5,300.9,180.2,469.6,381.4,519.5c32.6,7.7,461.9,11.5,958.4,11.5l900.9-3.8L5865.4,4637.3z M8144.4,3625.2c0-115-302.9-7425.7-314.4-7575.2c-15.3-218.5-107.3-375.7-270.3-461.9l-76.7-42.2H5000.9H2518.6l-76.7,42.2c-159.1,86.3-253,241.5-270.3,450.4c-9.6,130.3-314.4,7458.3-314.4,7586.7v76.7h3143.6h3143.6V3625.2z"
          />
          <path
            fill="currentColor"
            d="M3227.8,2312.2l-47.9-46V-549.6v-2815.8l44.1-38.3c23-21.1,57.5-44.1,76.7-49.8c46-17.3,159.1,30.7,184,78.6c30.7,57.5,28.8,5587.5-1.9,5645C3429.1,2371.6,3306.4,2392.7,3227.8,2312.2z"
          />
          <path
            fill="currentColor"
            d="M4891.6,2314.1l-53.7-46l-5.8-2787c-3.8-2114.2,1.9-2798.5,19.2-2840.7c55.6-138,260.7-122.7,302.9,21.1c15.3,51.7,19.2,856.8,15.3,2838.8l-5.8,2767.9l-53.7,46C5041.1,2373.5,4960.6,2373.5,4891.6,2314.1z"
          />
          <path
            fill="currentColor"
            d="M6570.7,2335.2c-17.2-13.4-40.2-42.2-51.7-65.2c-30.7-57.5-32.6-5587.5-1.9-5645c24.9-47.9,138-95.8,184-78.6c19.2,5.7,53.7,28.8,78.6,49.8l42.2,38.3v2815.8v2815.8l-47.9,46C6722.2,2363.9,6622.5,2375.4,6570.7,2335.2z"
          />
        </g>
      </g>
    </svg>
  )
}
