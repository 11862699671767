import { createAction } from '@reduxjs/toolkit'
import { TAllCurrencies, TCurrencies } from 'types/currencies'
import { COMMON_ACTION_TYPES } from './types'

export const fetchCurrencies = createAction(COMMON_ACTION_TYPES.FETCH_CURRENCIES_REQUEST)

export const fetchCurrenciesSuccess = createAction<
  TCurrencies,
  COMMON_ACTION_TYPES.FETCH_CURRENCIES_SUCCESS
>(COMMON_ACTION_TYPES.FETCH_CURRENCIES_SUCCESS)

export const fetchCurrenciesFailure = createAction(COMMON_ACTION_TYPES.FETCH_CURRENCIES_FAILURE)

export const updateCurrenciesPrice = createAction<
  Record<TAllCurrencies, number>,
  COMMON_ACTION_TYPES.FETCH_CURRENCIES_PRICE_UPDATE
>(COMMON_ACTION_TYPES.FETCH_CURRENCIES_PRICE_UPDATE)

export const setUploadPhotoModalState = createAction<boolean>(
  COMMON_ACTION_TYPES.SET_UPLOAD_PHOTO_MODAL_STATE,
)
