/* eslint-disable max-len */
import React from 'react'

import { ICustomIconProps } from 'types/common'

function CopyIcon({
  hover,
  colorHover = '#ffffff',
  color = '#ADADAD',
  width = '25',
  height = '25',
}: Readonly<ICustomIconProps>) {
  return (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <style>
        {`
            .CopyIconCLass {
                cursor: pointer;
                fill: ${color};
            }
            ${
              hover &&
              `
                .CopyIconCLass:hover{
                    fill: ${colorHover};
                }
                `
            }
        `}
      </style>
      <path
        className="CopyIconCLass"
        fillRule="evenodd"
        d="M4.756 9.514h8.655c1.146 0 2.074.929 2.074 2.038v8.691c0 1.109-.928 2.039-2.074 2.039H4.756a2.061 2.061 0 0 1-2.039-2.039v-8.691a2.06 2.06 0 0 1 2.039-2.038zm6.796-6.796h8.692c1.106 0 2.039.93 2.039 2.039v8.655c0 1.144-.933 2.074-2.039 2.074h-2.039v-3.934a4.71 4.71 0 0 0-1.394-3.326V8.19h-.037a4.732 4.732 0 0 0-3.363-1.395H9.513V4.757c0-1.109.93-2.039 2.039-2.039zm6.653 15.486h2.039a4.698 4.698 0 0 0 3.324-1.394l.035-.036a4.72 4.72 0 0 0 1.395-3.362V4.757c0-1.288-.534-2.468-1.395-3.363h-.035A4.702 4.702 0 0 0 20.244 0h-8.692a4.706 4.706 0 0 0-3.326 1.394h-.037a4.81 4.81 0 0 0-1.391 3.363v2.038H4.756c-1.287 0-2.467.537-3.361 1.395-.859.894-1.396 2.074-1.396 3.362v8.691c0 1.288.537 2.468 1.396 3.326v.036c.857.858 2.074.495 3.361.495h8.655c1.324 0 2.504.363 3.363-.495l.037-.036a4.71 4.71 0 0 0 1.394-3.326v-2.039z"
      />
    </svg>
  )
}

export default CopyIcon

export function IconCopy2({ size = 28 }: Readonly<ICustomIconProps>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 28 36">
      <path
        fillRule="evenodd"
        fill="currentColor"
        d="m27.584 7.461-5.787-6.154c-.278-.187-.624-.443-1.005-.443H8.5c-1.292 0-1.916.664-1.916 1.511v5.492L1.42 7.866C.623 7.866 0 8.53 0 9.377v24.986c0 .848.623 1.512 1.42 1.512h18.575c.797 0 1.421-.664 1.421-1.512v-5.49h5.163c.797 0 1.421-.664 1.421-1.511V8.567c0-.406-.139-.811-.416-1.106zM25.19 9.9h-4.849V4.71L25.19 9.9zm-6.616 22.953H2.841V10.888h3.743v16.437c0 .847.623 1.511 1.916 1.511h10.074v4.017zm-9.149-7.002V3.886H17.5v6.634c0 .848.623 1.79 1.42 1.79h6.238v13.504H9.425v.037z"
      />
    </svg>
  )
}

export function IconCopy3({ color = '#808080' }: Readonly<ICustomIconProps>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <path
        d="M6.80078 16C6.25078 16 5.77995 15.8042 5.38828 15.4125C4.99661 15.0208 4.80078 14.55 4.80078 14V2C4.80078 1.45 4.99661 0.979167 5.38828 0.5875C5.77995 0.195833 6.25078 0 6.80078 0H15.8008C16.3508 0 16.8216 0.195833 17.2133 0.5875C17.6049 0.979167 17.8008 1.45 17.8008 2V14C17.8008 14.55 17.6049 15.0208 17.2133 15.4125C16.8216 15.8042 16.3508 16 15.8008 16H6.80078ZM6.80078 14H15.8008V2H6.80078V14ZM2.80078 20C2.25078 20 1.77995 19.8042 1.38828 19.4125C0.996615 19.0208 0.800781 18.55 0.800781 18V4H2.80078V18H13.8008V20H2.80078Z"
        fill={color}
      />
    </svg>
  )
}
