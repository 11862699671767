import { IEnv } from './types'

const env: IEnv = {
  title: 'Playa',
  minutesUntilAutoLogout: '60',
  withCredentials: true,
  defaultStorageLimit: 50,
  chainid: 11155111,
  defaultCurrency: 'vne',
  defaultCurrencyName: 'Vine',
  googleKey: 'G-5WJ1JL36P9',
  termsOfUseVersion: 1,
  walletConnectProjectID: '95df3fcde395ec75cf214d361c34ecea',
  refid: 'ID6BE1887B18',
  removePeer: 'true',
  peerclubUrl: 'https://pc.peerclub.com',
  //
  captcha: {
    captchaType: 'turnstile',
    siteKey: '0x4AAAAAAADQoMhQrjV710_v',
    key: '0x4AAAAAAAcipsnDB0Bf6Yff',
  },
  //
  stripeKey:
    'pk_live_51NAycLKkGy6YYt11bIKEpFAtAOBE7lx9nZvdZfFGEHzTk3sOqPIr0rRKpKOHIT0zETlGM3TBjoGhUEbQ1PWVodSi00JhjBzxBi',
  //
  sentryKey: 'https://777a8b75ba32423aa85aa894817082e2@sentry.peer.inc/9',
  //
  baseUrl: 'https://api.peerclub.com',
  //
  api: {
    authUrl: 'https://uat-market.zeusbft.net/api/v2/barong',
    tradeUrl: 'https://uat-market.zeusbft.net/api/v2/peatio',
    applogicUrl: 'https://uat-market.zeusbft.net/api/v2/applogic',
    rangerUrl: 'wss://uat-market.zeusbft.net/api/v2/ranger',
    url: 'https://uat-market.zeusbft.net/api/v2',
    jumioUrl: 'https://lon.netverify.com/api',
    aliasUrl: 'https://alias.peerclub.com/api',
  },
}

export default env
