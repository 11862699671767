import * as Sentry from '@sentry/react'
import packageJson from '../../package.json'

export const getSentryKey = () => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return undefined
  }

  return window.env.sentryKey
}

Sentry.init({
  release: packageJson.version,
  dsn: getSentryKey(),
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
