import { TAllCurrencies, TCurrencies } from 'types/currencies'
import { TNullable } from 'types/utilityTypes'
import { createReducer } from '@reduxjs/toolkit'
import {
  fetchCurrencies,
  fetchCurrenciesFailure,
  fetchCurrenciesSuccess,
  setUploadPhotoModalState,
  updateCurrenciesPrice,
} from './actions'

const initialState = {
  price: {
    loading: false,
    data: null as TNullable<Record<TAllCurrencies, number>>,
  },
  currency: {
    loading: false,
    data: null as TNullable<TCurrencies>,
  },
  showPFPModal: false,
}

const commonReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCurrencies, (state) => ({
      ...state,
      currency: {
        ...state.currency,
        loading: true,
      },
    }))
    .addCase(fetchCurrenciesSuccess, (state, action) => ({
      ...state,
      currency: {
        loading: false,
        data: action.payload,
      },
    }))
    .addCase(updateCurrenciesPrice, (state, action) => ({
      ...state,
      price: {
        loading: false,
        data: action.payload,
      },
    }))
    .addCase(fetchCurrenciesFailure, (state) => ({
      ...state,
      currency: {
        ...state.currency,
        loading: false,
      },
    }))
    .addCase(setUploadPhotoModalState, (state, action) => ({
      ...state,
      showPFPModal: action.payload,
    }))
})

export default commonReducer
