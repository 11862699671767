import React, { memo, useMemo } from 'react'
import cn from 'classnames'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'

import MojiCard from '@components/MojiCard/CardSftPfp'
import CardOpened from '@components/UiKit/CardOpened'
import { TNullable } from 'types/utilityTypes'
import { ContractType, TEmojiItemPurchased, TokenGroup } from '@core/redux/emojis/types'

import styles from './style.module.scss'

interface Props {
  data?: TEmojiItemPurchased[]
  onSelectCard: (value: TNullable<TEmojiItemPurchased>) => void
  orderDetails?: number
  selectedCard: TNullable<TEmojiItemPurchased>
  isLargeCard?: boolean
  onSetHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, code: string) => void
  onClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: TEmojiItemPurchased,
    order: number,
  ) => void
}

function PurchasedContent({
  data,
  isLargeCard,
  selectedCard,
  onClick,
  orderDetails,
  onSetHandler,
  onSelectCard,
}: Readonly<Props>) {
  const { t } = useTranslation('nsMoji')

  const extraDescriptionItems = useMemo(
    () => [
      {
        title: t('card.additionInfo.balance'),
        value: Number(selectedCard?.balance)?.toFixed(0) || '',
      },
    ],
    [selectedCard?.token?.code],
  )

  return (
    <div className={styles.emojiContainer}>
      {data?.map((item, i) => {
        const { token } = item
        const contractAddress = item?.contract_address
        const appForbidden = token?.app_forbidden
        const tokenId = token?.token_id
        const name = token?.name
        const imgSrc = token?.media?.medium?.url || token?.media?.large?.url
        const code = token?.code
        const order = i + 1
        const balance = Number(item?.balance)
        const contractType =
          token.token_group === TokenGroup.sft ? ContractType.sft : ContractType.premium

        return (
          <MojiCard
            style={{
              order,
            }}
            className={cn(styles.card, { [styles['card--large']]: isLargeCard })}
            key={code}
            name={name}
            src={imgSrc}
            onClick={(e) => onClick(e, item, order)}
            quantity={balance > 1 ? balance : undefined}
            contractType={`ERC-${contractType}`}
            isActive={selectedCard?.token?.token_id === tokenId}
            onSet={(e) => onSetHandler(e, code)}
            appForbidden={appForbidden}
            contractAddress={contractAddress}
          />
        )
      })}
      {selectedCard && (
        <div style={{ order: orderDetails }} className={styles.detailsCard}>
          <CardOpened
            itemData={selectedCard.token}
            onCLose={() => {
              onSelectCard(null)
            }}
            extraDescriptionItems={extraDescriptionItems}
          />
        </div>
      )}
    </div>
  )
}

export default memo(PurchasedContent, isEqual)
