/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import BigNumber from 'bignumber.js'
import ReactGA from 'react-ga4'
import { differenceInYears, parse } from 'date-fns'

import { isProductionEnvironment } from '@constants/environments'

interface EventTrackerOptions {
  category: string
  action: string
  label?: string
  value?: number
  nonInteraction?: boolean
}

const eventTracker = ({
  category,
  action,
  label,
  value,
  nonInteraction = false,
}: EventTrackerOptions): null => {
  const options: EventTrackerOptions = { category, action, nonInteraction }

  if (label) options.label = label
  if (value) options.value = value

  if (isProductionEnvironment && category && action) {
    ReactGA.event(options)
  }

  return null
}

function getFees(amount: number, cartList: any[]): number {
  return cartList
    ?.map((i) => {
      if (i.fee) {
        return Number(i.fee)
      }
      const calculatedFee =
        i?.token?.fees_type === 'percentage' || i?.fees_type === 'percentage'
          ? Number(i?.token?.fees || i?.fees || 0) * (amount / 100)
          : Number(i?.token?.fees || i?.fees || 0)
      return calculatedFee
    })
    .reduce((partialSum, a) => partialSum + a, 0)
}

function getFee({
  feeType,
  fee,
  amount,
}: {
  feeType: string
  fee: number | string
  amount: number
}): number {
  if (!amount || !fee) return 0
  if (feeType === 'percentage') {
    return Number(fee || 0) * amount
  }

  return Number(fee || 0)
}

const calculateDefaultCoinPrice = (nftItem: any, currencyData: any): number => {
  const discount =
    (Number(nftItem?.price_usd || nftItem?.token?.price_usd || 0) / 100) *
    Number(nftItem?.price_peer || nftItem?.token?.price_peer || 0)

  return (Number(nftItem?.price_usd || nftItem?.token?.price_usd) - discount) / (currencyData || 0)
}

const convertDate = (utcDate: string | number | Date): string => {
  const date = new Date(utcDate)
  return date.toLocaleString()
}

const getTransactionId = (id: string): string => {
  if (!id) return ''
  return `${`${id.slice(0, 6)}---${id.slice(-6)}`}`
}

const capitalize = (word: string): string => (word ? word[0].toUpperCase() + word.slice(1) : word)

const toFixed = (num: number | string, tofixed = 2): string => new BigNumber(num).toFixed(tofixed)

const truncateData = (num: number | string, fixed = 0): string => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`)
  let number
  if (num === 0) {
    number = num.toFixed(fixed)
  } else {
    number = new BigNumber(num || 0).toFixed()
  }
  if (number == Infinity || number == null) {
    return '0'
  }
  return number?.toString()?.match(re)[0] || '0'
}

const convertPmc = (pmc: number, usd: number, amount: number): number => {
  const usdToCoinPrice = 1 / pmc
  const latestPrice = usd * usdToCoinPrice
  const pmcAmount = latestPrice * amount
  return pmcAmount || 0
}

const numberWithCommas = (x: number | string): string =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

let timezone = ''

const getTimezone = (): string =>
  timezone.length > 0 ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone

const setTimezone = (tz: string): null => {
  timezone = tz
  return null
}

const removeQueryParams = (searchParams: URLSearchParams, queryKey: string): void => {
  const param = searchParams.get(queryKey)
  if (param) {
    // delete each query param
    searchParams.delete(queryKey)
    // update state after
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}${
        searchParams.toString().length ? `?${searchParams.toString()}` : ''
      }`,
    )
  }
}

const removeEmptyValues = (obj: any): any => {
  const copiedObj = { ...obj }
  Object.entries(copiedObj).forEach(([key, val]) => {
    if (val === undefined) {
      delete copiedObj[key]
    }
  })

  return copiedObj
}

const getPositionElement = (element: HTMLElement): { x: number; y: number } => {
  const c = element.getBoundingClientRect()
  const y = window.scrollY + c.top
  const x = window.scrollX + c.left
  return {
    x,
    y,
  }
}

export const getMissedFields = (profileData: any): string[] => {
  const result: string[] = []

  if (!profileData) return result

  if (!profileData.first_name || !profileData.last_name) {
    result.push('name')
  }

  if (!profileData.dob && !profileData?.profiles?.[0]?.dob) {
    result.push('dob')
  }

  if (!profileData.email) {
    result.push('email')
  }

  if (!profileData.username) {
    result.push('username')
  }

  if (!profileData.phone_number && !profileData?.phones?.[0]?.number) {
    result.push('phone')
  }

  return result
}

const getAge = (date: string): number | null => {
  if (date) {
    const parsedDate = parse(date, 'yyyy-MM-dd', new Date())
    return differenceInYears(new Date(), parsedDate)
  }

  return null
}

export const shouldUpdateFields = ({
  full_name,
  username,
  dob,
}: {
  full_name: string
  username: string
  dob: string
}): boolean => !full_name || !username || !dob

export const shouldUpdatePhone = ({ phone_number }) => !phone_number

const LOGIN_PHONE_KEY = 'login_phone'
const LOGIN_EMAIL_KEY = 'login_email'
const PENDING = 'pending'

export const shouldVerifyPhone = (userData) =>
  userData?.labels?.some(({ key, value }) => key === LOGIN_PHONE_KEY && value === PENDING)

export const shouldVerifyEmail = (userData) =>
  userData?.labels?.some(({ key, value }) => key === LOGIN_EMAIL_KEY && value === PENDING)

const helpers = {
  capitalize,
  calculateDefaultCoinPrice,
  convertDate,
  convertPmc,
  eventTracker,
  getTransactionId,
  getFees,
  getTimezone,
  setTimezone,
  getFee,
  numberWithCommas,
  toFixed,
  truncateData,
  removeQueryParams,
  removeEmptyValues,
  getPositionElement,
  getAge,
  shouldUpdateFields,
}

export default helpers
