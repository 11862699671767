import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import { TNullable } from 'types/utilityTypes'
import { TEmojiItemPurchased } from '@core/redux/emojis/types'
import { ITransactionTypes } from '@components/Profile/Transactions/main/types'

import { createReducer } from '@reduxjs/toolkit'
import encryptor from '../../encryptor'
import { IReducerPurchaseTokens } from './type'
import {
  fetchPurchasedTokens,
  fetchPurchasedTokensFailure,
  fetchPurchasedTokensSuccess,
  fetchTransactions,
  fetchTransactionsFailure,
  fetchTransactionsSuccess,
} from './actions'

export const initialState: {
  purchase: {
    data: IReducerPurchaseTokens<TNullable<TEmojiItemPurchased[]>>
    loading: boolean
    error: Error | string
  }
  transactions: Record<
    keyof ITransactionTypes,
    {
      data: IReducerPurchaseTokens<TNullable<TEmojiItemPurchased[]>>
      loading: boolean
      error: Error | string
    }
  >
} = {
  purchase: {
    data: {
      items: null,
      total: 0,
      page: 0,
      perPage: 0,
    },
    loading: false,
    error: '',
  },
  transactions: Object.keys(ITransactionTypes).reduce(
    (acc, item) => ({
      ...acc,
      [item]: {
        data: {
          items: null,
          total: 0,
          page: 0,
          perPage: 0,
        },
        loading: false,
        error: '',
      },
    }),
    {} as unknown as Record<
      keyof ITransactionTypes,
      {
        data: IReducerPurchaseTokens<TNullable<TEmojiItemPurchased[]>>
        loading: boolean
        error: Error | string
      }
    >,
  ),
}

const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPurchasedTokens, (state) => ({
      ...state,
      purchase: {
        ...state.purchase,
        error: '',
        loading: true,
      },
    }))
    .addCase(fetchPurchasedTokensSuccess, (state, action) => ({
      ...state,
      purchase: {
        data: action.payload,
        loading: false,
        error: '',
      },
    }))
    .addCase(fetchPurchasedTokensFailure, (state, action) => ({
      ...state,
      purchase: {
        ...initialState.purchase,
        error: action.payload.error,
        loading: false,
      },
    }))
    .addCase(fetchTransactions, (state, action) => {
      const itemStateTransactionType = action.payload?.transactionType
        ? state.transactions[action.payload?.transactionType]
        : undefined

      return {
        ...state,
        transactions: {
          ...state.transactions,
          [action.payload?.transactionType]: {
            ...itemStateTransactionType,
            error: '',
            loading: true,
          },
        },
      }
    })
    .addCase(fetchTransactionsSuccess, (state, action) => ({
      ...state,
      transactions: {
        ...state.transactions,
        [action.payload?.transactionType]: {
          data: action.payload.payload,
          error: '',
          loading: false,
        },
      },
    }))
    .addCase(fetchTransactionsFailure, (state, action) => ({
      ...state,
      transactions: {
        ...state.transactions,
        [action.payload?.transactionType]: {
          ...initialState.transactions[action.payload?.transactionType],
          error: action.payload.error,
          loading: false,
        },
      },
    }))
})

const profileConfig = {
  key: 'peer_purchased_tokens',
  storage,
  transforms: [encryptor],
}

export default persistReducer(profileConfig, profileReducer)
