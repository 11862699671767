import React from 'react'
import { confirmable, createConfirmation } from 'react-confirm'
import styles from './styles.module.scss'

const DEFAULT_OPTIONS = {
  title: 'Are you sure you want to this?',
  acceptText: 'Yes, I am sure',
  declineText: 'No',
}

interface Props {
  show?: boolean
  proceed?: (value: boolean) => void
  title?: string
  message?: string
  acceptText?: string
  declineText?: string
}

const AsyncConfirmDialog = confirmable(
  ({ show, proceed, title, message, acceptText, declineText }: Readonly<Props>) => {
    if (!show) {
      return null
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.title}>{title}</div>
          {message && <div className={styles.text}>{message}</div>}
          <div className={styles.footer}>
            <button
              className={styles.declineButton}
              type="button"
              onClick={() => {
                proceed?.(false)
              }}
            >
              <span>{declineText}</span>
            </button>
            <button
              className={styles.agreeButton}
              type="button"
              onClick={() => {
                proceed?.(true)
              }}
            >
              <span>{acceptText}</span>
            </button>
          </div>
        </div>
      </div>
    )
  },
)

const confirm = createConfirmation(AsyncConfirmDialog)

/**
 * Util that show confirmation dialog with promised result - comfortable for logout / remove confirmation / etc
 * @param confirmation - string
 * @param options - dialog options
 * @returns Promise
 *
 * Example of usage:
 * const result = await asyncConfirm('Are you sure?')
 * if(result) {
 *   ...
 * } else {
 *   ...
 * }
 */
export function asyncConfirm({
  title = DEFAULT_OPTIONS.title,
  message,
  acceptText = DEFAULT_OPTIONS.acceptText,
  declineText = DEFAULT_OPTIONS.declineText,
}) {
  return confirm({
    title,
    message,
    acceptText,
    declineText,
  })
}
