import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile'
import { useDispatch, useSelector } from 'react-redux'
import { resetCaptcha, setCaptcha } from '@core/redux/auth/actions'
import { useLocation } from 'react-router-dom'
import { addAppListener, AppDispatch } from '@core/redux/store'
import { configSelectors } from '@core/redux/selectors'

interface ICaptchaContext {
  captchaKey: string
  onSuccess: (value: string) => void
  ref: React.RefObject<TurnstileInstance | undefined>
  onExpire: () => void
  onError: () => void
  captchaValue: string
  reset: () => void
}

const initialValue = {
  captchaKey: '',
  onSuccess: () => {},
  ref: React.createRef<TurnstileInstance | undefined>(),
  onExpire: () => {},
  onError: () => {},
  reset: () => {},
  captchaValue: '',
}

const CaptchaContext = createContext<ICaptchaContext>(initialValue)

export function CaptchaProvider({ children }) {
  const [captchaValue, setCaptchaValue] = useState<string>('')
  const ref = useRef<TurnstileInstance | undefined>(null)
  const dispatch = useDispatch<AppDispatch>()
  const step = useSelector(configSelectors.authStep)
  const location = useLocation()

  const reset = () => {
    ref?.current?.reset()
    setCaptchaValue('')
  }
  const onExpire = reset
  const onError = reset

  const onSuccess = (value: string) => {
    setCaptchaValue(value)
    dispatch(setCaptcha(value))
  }

  useEffect(() => {
    const listenerEntrySuccess = dispatch(
      addAppListener({
        actionCreator: resetCaptcha,
        effect: () => reset(),
      }),
    )

    return () => {
      listenerEntrySuccess()
    }
  }, [])

  useEffect(reset, [step, location.pathname])

  const captchaKey = window.env?.captcha?.key

  const value = useMemo(
    () => ({
      captchaKey,
      onSuccess,
      ref,
      onExpire,
      onError,
      captchaValue,
      reset,
    }),
    [captchaKey, ref, onExpire, onError, captchaValue, reset, onSuccess],
  )

  return (
    <CaptchaContext.Provider value={value}>
      {children}
      <Turnstile
        siteKey={captchaKey}
        onSuccess={onSuccess}
        ref={ref}
        onExpire={onExpire}
        onError={onError}
        options={{
          size: 'invisible',
        }}
      />
    </CaptchaContext.Provider>
  )
}

export const useCaptcha = () => useContext(CaptchaContext)
