import React, { memo } from 'react'
import PaginationReact, { ReactPaginateProps } from 'react-paginate'
import isEqual from 'react-fast-compare'
import cn from 'classnames'
import { PartialBy } from 'types/utilityTypes'

import styles from './style.module.scss'

interface Props extends PartialBy<ReactPaginateProps, 'pageCount'> {
  total: number
  itemsPerPage: number
  renderOnOnePageCount?: boolean
}

function Pagination({
  className,
  total = 1,
  itemsPerPage = 1,
  nextLabel = '>',
  previousLabel = '<',
  pageRangeDisplayed = 5,
  pageCount,
  renderOnOnePageCount = false,
  ...rest
}: Readonly<Props>) {
  const pageCounted = pageCount || Math.ceil(total / itemsPerPage)

  if (!renderOnOnePageCount && pageCounted < 2) {
    return null
  }

  return (
    <PaginationReact
      className={cn(styles.pagination, className)}
      pageCount={pageCounted}
      pageRangeDisplayed={pageRangeDisplayed}
      nextLabel={nextLabel}
      previousLabel={previousLabel}
      {...rest}
    />
  )
}

export default memo(Pagination, isEqual)
