/* eslint-disable prefer-const */
/* eslint-disable no-console */
import { toast } from 'react-toastify'
import get from 'lodash.get'
import { Action } from '@reduxjs/toolkit'

import { errorMessages, isDev } from '@constants/index'

// eslint-disable-next-line import/no-cycle
import { store } from './redux/store'

export const getErrorText = (path: string | string[]): string =>
  get(errorMessages, path || '', errorMessages.default)

export const getErrorTextHandler = (err: Error): string => {
  const { message } = err
  const isStringMessage = typeof message === 'string'
  const messageText = getErrorText(isStringMessage ? message : message?.[0])
  return messageText
}

type ErrorObject = {
  message?: string | string[] | null
  errors?: string[] | null | unknown
  code?: string | null
}

type ErrorHandlerOptions = {
  errorCallback?: ((errorMessage: string | string[] | null | undefined) => void) | null
  showToastErrors?: boolean
}

type ProcessedError = {
  messageText: string | null
  errorsText: string[] | null | undefined
}

const processError = (error: ErrorObject | ErrorObject[] | string): ProcessedError => {
  let { message = null, errors = null, code = null } = error as ErrorObject

  if (Array.isArray(error)) {
    errors = error as unknown as string[]
  }

  const isStringMessage = typeof message === 'string'

  if (!isStringMessage && (message?.length ?? 0) > 1) {
    errors = message
    message = null
  }

  if (isStringMessage && code && typeof code === 'string') {
    message = code
  }

  const messageText = getErrorText(isStringMessage ? message ?? '' : message?.[0] ?? '')
  const errorsText = (errors as string[] | null)?.map((e) => getErrorText(e))

  return { messageText, errorsText }
}

export const errorsHandler = (
  error: ErrorObject | ErrorObject[] | string,
  { errorCallback = null, showToastErrors = false }: ErrorHandlerOptions = {},
): boolean | void => {
  if (typeof error === 'string') {
    if (errorCallback) {
      errorCallback(error)
    }
    toast.error(error)
  }

  const { messageText, errorsText } = processError(error)

  if (isDev) {
    console.error(error)
  }

  if (errorCallback) {
    store.dispatch(errorCallback(messageText || errorsText) as unknown as Action)

    if (!showToastErrors) {
      return false
    }
  }

  if (messageText) {
    toast.error(messageText)
  }

  return errorsText?.forEach((e) => toast.error(e))
}

/**
 * @param {Object} stateLoading loading state.
 * @param {String} key key of loading.
 * @param {Boolean} value value.
 */

export const setLoading = <T>(stateLoading: T, key: string, value = true): T => ({
  ...stateLoading,
  [key]: value,
})

export default {
  errorsHandler,
}
