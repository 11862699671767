import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './menuItem.module.scss'

interface IMenuItemProps {
  id: number
  isRouter?: boolean
  label?: string
  onHover: (id: number | null) => void
  isHovered?: boolean
  status?: string
  to: string
  activeRoutes?: string[]
  setIsProfileDropdown: (value: boolean) => void
}

function MenuItem({
  id,
  label,
  isRouter = true,
  onHover,
  isHovered,
  status = 'default',
  to,
  activeRoutes,
  setIsProfileDropdown,
}: IMenuItemProps) {
  const handleMouseEnter = () => {
    onHover(id)
  }

  const handleMouseLeave = () => {
    onHover(null)
  }

  return (
    <li
      className={cn(styles.item, styles[status], {
        [styles.disabled]: status === 'coming soon',
        [styles.hovered]: isHovered,
        [styles.isActive]: activeRoutes
          ? activeRoutes.includes(document.location.pathname)
          : to === document.location.pathname,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setIsProfileDropdown(false)}
    >
      {status !== 'default' ? (
        <span className={cn(styles.textGroup)}>
          {label}{' '}
          <span
            className={cn(styles.colored, {
              [styles.hovered]: isHovered,
            })}
          >
            {status}
          </span>
        </span>
      ) : isRouter ? (
        <Link to={to} className={styles.link}>
          {label}
        </Link>
      ) : (
        <a href={to} className={styles.link} target="_blank" rel="noreferrer">
          {label}
        </a>
      )}
    </li>
  )
}

export default MenuItem
