/* eslint-disable import/no-unresolved */
import React from 'react'
import cn from 'classnames'
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalState } from '@web3modal/ethers/react'
import { useTranslation } from 'react-i18next'
import Typography from '@components/UiKit/Typography'
import { TSize } from '@components/UiKit/types'
import useCurrentWidth from '@hooks/useCurrentWidth'
import useDisableScroll from '@hooks/useDisableScroll'
import Avatar from '../UiKit/Avatar'
import Button from '../UiKit/ButtonNew/Button'

import styles from './page_header.module.scss'

interface IPageHeaderProps {
  size?: TSize | undefined
  showConnectWallet?: boolean
  fullName?: string
  userName?: string
  srcUserImage?: string
  isEditable?: boolean
  isPZone?: boolean
  isUserImageOnCenter?: boolean
  isEditProfileButton?: boolean
  onEditUserImageClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onEditCoverImageClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  className?: string
  userImageIsNft?: boolean
}

function PageHeader({
  userName,
  fullName,
  size,
  showConnectWallet = false,
  isEditable,
  isPZone = true,
  srcUserImage,
  isUserImageOnCenter,
  onEditUserImageClick,
  onEditCoverImageClick,
  className,
  userImageIsNft,
}: Readonly<IPageHeaderProps>) {
  const { open } = useWeb3Modal()
  const { address = '', isConnected } = useWeb3ModalAccount()
  const { open: isWeb3ModalOpened } = useWeb3ModalState()
  useDisableScroll(isWeb3ModalOpened)

  const { t } = useTranslation('nsCommon')

  const { isMobile, isTablet, isLaptop } = useCurrentWidth()

  const getSize = (specificSize: TSize | undefined) => {
    if (isMobile || TSize.small === specificSize) return TSize.small
    if (isTablet || isLaptop || TSize.compact === specificSize) return TSize.compact
    return TSize.spacious
  }

  return (
    <div onClick={onEditCoverImageClick} className={cn(styles.container, className)}>
      <div className={cn(styles.section, styles[getSize(size)])}>
        <div className={cn(styles.avatar_section, styles[getSize(size)])}>
          <div
            className={cn(styles.avatar_wrapper, styles[getSize(size)], {
              [styles.center]: isUserImageOnCenter,
            })}
          >
            <Avatar
              src={srcUserImage}
              isEditable={isEditable}
              withBorder={userImageIsNft}
              size={getSize(size)}
              onClick={onEditUserImageClick}
            />
          </div>
          <div className={styles.profile_section}>
            {isPZone && userName && (
              <div className={styles.pzone_section}>
                <Typography.Heading className={styles.name}>{fullName}</Typography.Heading>
              </div>
            )}
            {showConnectWallet &&
              (isConnected ? (
                <Button
                  className={styles.button_edit}
                  onClick={() => open({ view: 'Account' })}
                  variant="secondary"
                  size={TSize.small}
                >
                  {`${address.slice(0, 4)}...${address.slice(-4)}`}
                </Button>
              ) : (
                <Button
                  className={styles.button_edit}
                  onClick={() => open()}
                  variant="secondary"
                  size={TSize.small}
                >
                  {t('connectWallet')}
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(PageHeader)
