import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { configSelectors } from '@core/redux/selectors'
import styles from './user_icon.module.scss'

interface IUserIconProps {
  onClick: () => void
  isInteractive?: boolean
  isSmall?: boolean
}

function UserIcon({ onClick, isInteractive = true, isSmall = false }: IUserIconProps) {
  const profile = useSelector(configSelectors.profile)
  const profilePhotoUrl = profile?.profile_url?.url

  const profilePhotoFallbackLetter = profile?.first_name
    ? profile?.first_name?.[0]
    : profile?.username?.[0] || profile?.email?.[0]

  const handleAction = () => {
    if (isInteractive) onClick()
  }

  return (
    <div
      className={cn(styles.user_image, {
        [styles.non_interactive]: !isInteractive,
        [styles.small]: isSmall,
      })}
      onClick={handleAction}
    >
      {profilePhotoUrl ? (
        <img src={profilePhotoUrl} alt="user" />
      ) : (
        <div>{profilePhotoFallbackLetter}</div>
      )}
    </div>
  )
}

export default memo(UserIcon)
