import { Method } from 'axios/index'
import { TNullable } from 'types/utilityTypes'

export enum ApiVersion {
  barong = 'barong',
  applogic = 'applogic',
  peatio = 'peatio',
  alias = 'alias',
}

export interface IRequestConfig {
  body?: unknown
  method?: Method | string
  url?: string
}

export interface IApiConfig {
  apiVersion: ApiVersion
  withHeaders?: boolean
}

export interface FormattedError {
  code: number
  message: string[]
}

export enum Platform {
  zen = 'zen',
  app = 'app',
}

export type TLabel = {
  key: string
  value: string
  scope: string
  created_at: string
  updated_at: string
}

export interface IMemberModel {
  uid: string
  email: string
  agreement: string
  minimum_swap_amount: number
  accounts: [
    {
      currency: string
      balance: number
      locked: number
      deposit_address: {
        currencies: ('btc' | 'eth' | 'usdt' | 'vc1' | 'vne')[]
        address: string
        state: string
      }
      limit: number
      user_amount: number
    },
  ]
}
export interface IUserModel {
  email: string
  first_name: string
  last_name: string
  full_name: string
  userName: string
  username: string
  uid: string
  dob: string
  role: string
  level: number
  otp: true
  profile_url: { url: string; display_picture: boolean }
  profile_type: string
  state: string
  country: string
  country_of_residence: string
  last_country: string
  last_ip: string
  phone_number: string
  data: string
  csrf_token: string
  authentication: string
  agreement: string
  referral_code: string
  login_via_password: string
  labels: TLabel
  fee?: IMemberModel
  usa_disclaimer?: boolean
  phones: {
    country: string
    number: string
    validated_at: string
  }
  profiles: {
    first_name: string
    middle_name: string
    last_name: string
    dob: string
    address: string
    postcode: string
    city: string
    country: string
    state: string
    metadata: unknown
    created_at: string
    updated_at: string
  }[]
  data_storages: {
    title: string
    data: string
    created_at: string
    updated_at: string
  }
  created_at: string
  updated_at: string
  agreement_time: string
  wallet_address: TNullable<string>
  captchaValue: string
}

export interface IProfileModel {
  email: string
  first_name: string
  last_name: string
  middle_name?: string
  full_name: string
  username: string
  phone_number: string
  csrf_token: string
  uid: string
  role: string
  level: number
  profile_url: unknown
  profile_type: string
  otp: boolean
  state: string
  country: string
  country_of_residence: string
  last_country: string
  last_ip: string
  agreement: string
  dob: string
  referral_code: string
  login_via_password: string
  verified: unknown
  is_blue_verified: unknown
  labels: TLabel
  created_at: string
  updated_at: string
  agreement_time: string
  confirm?: boolean
}

export type TEmailNotificationItem = {
  name: string
  description: string
  enabled: boolean
  email_type?: string
}
