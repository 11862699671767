// env import should be first
import './env'
import './sentry'
import React from 'react'
import { createRoot, Container } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'

import { Web3Provider } from '@context/web3Provider'
import rootSaga from '@core/redux/rootSagaWatcher'
import mintBg from '@assets/images/mint_horizontal.png'
import landingBg from '@assets/images/landing/citybg.webp'
import flame from '@assets/images/landing/largeflame.png'
import Logo from '@assets/images/logo/checkout_logo.svg'
import CookiesDisclaimer from '@components/CookiesDisclaimer'
import { enableMocks, isDev } from '@constants/environments'
import { persistor, sagaMiddleware, store } from '@core/redux/store'
import AuthApiGate from '@routing/AuthApiGate'
import { LocalizationProvider } from '@context/LocalizationProvider'
import { OtpTimerProvider } from '@context/otpTimer'
import { CaptchaProvider } from '@context/CaptchaProvider'
import { history } from '@routing/history'

import App from './App'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'

import './index.scss'

sagaMiddleware.run(rootSaga)

if (isDev && enableMocks) {
  require('./mocks')
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement as Container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3Provider>
        <PersistGate loading={null} persistor={persistor}>
          {/* @ts-ignore */}
          <BrowserRouter history={history}>
            <AuthApiGate>
              <LocalizationProvider>
                <OtpTimerProvider>
                  <CaptchaProvider>
                    <App />

                    {/* content to preload */}
                    <div style={{ display: 'none' }}>
                      <img src={mintBg} alt="" />
                      <img src={landingBg} alt="" />
                      <img src={flame} alt="" />
                      <img src={Logo} alt="" />
                    </div>
                  </CaptchaProvider>
                </OtpTimerProvider>
              </LocalizationProvider>
            </AuthApiGate>
          </BrowserRouter>
        </PersistGate>
        <ToastContainer />
        <CookiesDisclaimer />
      </Web3Provider>
    </Provider>
  </React.StrictMode>,
)
