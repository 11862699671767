import T from 'prop-types'
import { useDispatch } from 'react-redux'
import { matchPath, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import axios from 'axios'

import routes from '@routing/path'
import { fetchProfileFailure } from '@core/redux/profile/actions'

const pathToIgnoreUnauthorizedInterceptor = [
  ...Object.values(routes.withoutAuth),
  routes.test,
  routes.withAuth.moji,
]

export default function AuthApiGate({ children }: React.PropsWithChildren) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          dispatch(fetchProfileFailure())

          const { pathname } = window.location

          if (
            pathToIgnoreUnauthorizedInterceptor.every(
              (path) => matchPath(path, pathname.toLowerCase()) === null,
            )
          ) {
            navigate(routes.withoutAuth.auth)
          }
        }

        return Promise.reject(error)
      },
    )
  }, [])

  return children as React.JSX.Element
}

AuthApiGate.propTypes = {
  children: T.node,
}
