import React from 'react'
import { Link } from 'react-router-dom'
import { ColumnsType, DefaultRecordType } from 'rc-table/lib/interface'

import Table from '@components/UiKit/Table'
import Pagination from '@components/Pagination'
import routes from '@routing/path'
import styles from './style.module.scss'

const ITEMS_COUNT_PER_PAGE = 10

interface Props {
  columns: ColumnsType<DefaultRecordType>
  data: Record<string, any>[]
  loading?: boolean
  total?: number
  handlePageChange?: (currentPage: { selected: number }) => void
  page?: number
}

function TransactionsDetails({
  columns,
  data,
  loading,
  total,
  handlePageChange,
  page,
}: Readonly<Props>) {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Detailed Transactions</h2>

      <div className={styles.wrap_backBtn}>
        <Link to={routes.withAuth.profile.transactions}>Go to all transactions</Link>
      </div>

      <Table
        displayedMobileCards
        columns={columns}
        data={data}
        loading={loading}
        tableLayout="fixed"
        emptyText="You don't have any transactions yet"
      />
      {total && (
        <Pagination
          itemsPerPage={ITEMS_COUNT_PER_PAGE}
          total={total}
          onPageChange={handlePageChange}
          forcePage={page}
        />
      )}
    </div>
  )
}

export default TransactionsDetails
