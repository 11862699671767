/* eslint-disable react/jsx-no-comment-textnodes */
import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { configSelectors } from '@core/redux/selectors'
import useOnClickOutside from '@hooks/useOnClickOutside'
import UserIcon from '@components/UiKit/Avatar/UserIcon'
import ProfileDropdown from '@components/UiKit/ProfileDropdown'
import { LocalizationContext } from '@context/LocalizationProvider'
import { isDevOrDevEnvironment } from '@constants/environments'
import styles from './right_section.module.scss'

function RightSection() {
  const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false)
  const { isAuth } = useSelector(configSelectors.isAuthenticated)
  const additionalUserDataCompleted = useSelector(configSelectors.additionalUserDataCompleted)
  const isPhoneVerified = useSelector(configSelectors.isPhoneVerified)
  const isEmailVerified = useSelector(configSelectors.isEmailVerified)
  const profile = useSelector(configSelectors.profile)
  const ref = useRef<HTMLInputElement>(null)

  const userName = `${profile?.first_name} ${profile?.last_name}`

  const handleAvatarClick = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }

  const onScroll = () => {
    setIsProfileDropdown(false)
  }

  useOnClickOutside(ref, () => {
    setIsProfileDropdown(false)
  })

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const localizationContext = useContext(LocalizationContext)
  if (!localizationContext) return null
  const { isWithKey, toggleIsWithKey } = localizationContext

  return (
    <div className={styles.wrapper}>
      {isDevOrDevEnvironment && (
        <button
          type="button"
          onClick={toggleIsWithKey}
          className={cn(styles.lang, styles.keyBtn, {
            [styles.key_active]: isWithKey,
          })}
        >
          lang keys
        </button>
      )}
      {isAuth && additionalUserDataCompleted && isPhoneVerified && isEmailVerified && (
        <div ref={ref} className={styles.profile_image_wrapper}>
          <UserIcon onClick={handleAvatarClick} />
          {isProfileDropdown && (
            <div className={styles.profile_dropdown_wrapper}>
              <ProfileDropdown setIsProfileDropdown={setIsProfileDropdown} userName={userName} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(RightSection)
