import React, { memo, useEffect, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TNullable } from 'types/utilityTypes'
import helpers from '@utils/helpers'
import { POPUP_TITLES } from '@constants/popupTitles'
import PageHeader from '@components/PageHeader'
import NavBar from '@components/UiKit/NavBar'
import { configSelectors } from '@core/redux/selectors'

import Popup from '@components/UiKit/Popup'
import styles from './style.module.scss'
import { createPagesList } from './Layout.settings'

function Layout() {
  const profile = useSelector(configSelectors.profile)
  const [congratsPopupOpen, setCongratsPopupOpen] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const congratsParam: TNullable<string> = searchParams.get('purchase_congrats')
  const { t } = useTranslation('nsUserProfile')
  const pagesList = createPagesList(t)

  const closePopup = () => setCongratsPopupOpen(false)

  useEffect(() => {
    if (congratsParam) {
      setCongratsPopupOpen(true)
      helpers.removeQueryParams(searchParams, 'purchase_congrats')
    }
  }, [congratsParam])

  const CONGRATS_TEXT = POPUP_TITLES.payment.congrats

  return (
    <>
      {congratsPopupOpen && (
        <Popup text={CONGRATS_TEXT} buttonText={t('closeBtn')} action={closePopup} />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <PageHeader
            srcUserImage={profile?.profile_url?.url}
            userImageIsNft={profile?.profile_type !== 'default'}
            userName={profile?.username}
            fullName={profile?.full_name}
            isEditProfileButton
            showConnectWallet
          />

          <NavBar
            wrapperClassName={styles.navBar}
            fullWidth
            align="middle"
            size="medium"
            items={pagesList}
          />

          <div className={styles.main}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(Layout, isEqual)
