export enum ITransactionTypes {
  recentActivities = 'recentActivities',
  cryptoTX = 'cryptoTX',
  quote = 'quote',
  withdraw = 'withdraw',
  purchase = 'purchase',
  bonus = 'bonus',
  nfe = 'nfe',
  wonkabots = 'wonkabots',
}

export interface ITtransactionTabItem {
  type: ITransactionTypes
  text: string
  isActive: boolean
}
