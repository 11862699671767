import React, { useState, useCallback, useEffect, useRef, useMemo, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

import { configSelectors } from '@core/redux/selectors'
import PlayaNFTs from '@containers/Settings/Modals/PlayaNFTs'
import EmptyNFTsData from '@containers/Settings/Modals/EmptyNFTsData'
import { TSize } from '@components/UiKit/types'
import { API } from '@core/api'
import {
  IEmojiType,
  IHeadersType,
  IWonkaType,
  IExternalEmojiType,
} from '@containers/Settings/Modals/PlayaNFTs/types'
import { ApiVersion } from '@core/api/types'
import { AppDispatch } from '@core/redux/store'
import { setUploadPhotoModalState } from '@core/redux/common/actions'
import { IconPhoto, IconProfile } from '../Icons'
import CameraButton from '../../ButtonNew/CameraButton'
import { IPlayaTypes } from './types'
import { IMAGE_URLS_BY_TYPE } from './PlayaSettings'
import styles from './popup.module.scss'

export interface ITPlayaItem {
  children: string
  key: IPlayaTypes
}

export interface IPopupType {
  size?: TSize
  onSelectFile: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const config = {
  apiVersion: ApiVersion.peatio,
  withHeaders: true,
}

const ITEMS_COUNT_PER_PAGE = 30

const defaultStatePages = Object.fromEntries(Object.values(IPlayaTypes).map((type) => [type, 1]))

function Popup(props: IPopupType, childRef: React.RefObject<HTMLInputElement>) {
  const { size = TSize.small, onSelectFile } = props
  const randomId = useMemo(() => crypto.randomUUID(), [])
  const { t } = useTranslation('nsUserProfile')
  const dispatch = useDispatch<AppDispatch>()
  const showPFPModal = useSelector(configSelectors.showPFPModal)

  const [isPopupVisible, setPopupVisible] = useState(false)
  const [keyId, setKeyId] = useState(randomId)

  const [headers, setHeaders] = useState<IHeadersType>({
    [IPlayaTypes.emoji]: { total: '' },
    [IPlayaTypes.wonka]: { total: '' },
    [IPlayaTypes.externalEmoji]: { total: '' },
  })

  const [page, setPage] = useState(defaultStatePages)
  const [items, setItems] = useState<
    | Record<IPlayaTypes.emoji, IEmojiType[] | undefined>
    | Record<IPlayaTypes.wonka, IWonkaType[] | undefined>
    | Record<IPlayaTypes.externalEmoji, IExternalEmojiType[] | undefined>
  >({
    [IPlayaTypes.emoji]: undefined,
    [IPlayaTypes.wonka]: undefined,
    [IPlayaTypes.externalEmoji]: undefined,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const tokenList = useSelector(configSelectors.externalNFTList)

  const fetchData = useCallback(
    async (type: IPlayaTypes): Promise<void> => {
      const urlToFetch = type !== IPlayaTypes.externalEmoji ? IMAGE_URLS_BY_TYPE[type] : ''
      if (!urlToFetch) return

      const url = queryString.stringifyUrl({
        url: urlToFetch,
        query: {
          limit: ITEMS_COUNT_PER_PAGE,
          page: page[type] || 1,
          app_forbidden: false,
        },
      })

      setIsLoading(true)
      setError(null)

      try {
        const res = await API.get(config)(url)

        const { data, headers } = res as {
          headers: IHeadersType
          data: IEmojiType[] | IWonkaType[]
        }

        setHeaders((preItems) => ({
          ...preItems,
          [type]: headers,
        }))

        setItems((prevItems) => ({
          ...prevItems,
          [type]: [...(prevItems[type] ?? []), ...data],
          externalEmoji: tokenList,
        }))

        setPage((prevPage) => ({
          ...prevPage,
          [type]: prevPage[type] + 1,
        }))
      } catch (error) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [page, items],
  )

  const isLoadingImages = isLoading

  const { isEmojiOrWonkaDataWhileFetching, isEmojiOrWonkaData } = useMemo(
    () => ({
      isEmojiOrWonkaDataWhileFetching: Object.values(items).some((item) => item === undefined),
      isEmojiOrWonkaData: Object.values(items).some((item) => item?.length > 0),
    }),
    [items],
  )

  const popupRef = useRef<HTMLDivElement>(null)

  const onOpenPopup = () => {
    if (!isEmojiOrWonkaData) {
      Object.values(IPlayaTypes).forEach((type) => fetchData(type))
    }

    setPopupVisible(true)
  }

  const handleShowModal = useCallback(() => {
    dispatch(setUploadPhotoModalState(true))
  }, [])

  const handleCancelModal = useCallback(() => {
    dispatch(setUploadPhotoModalState(false))
  }, [])

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectFile(e)
    setPopupVisible(false)
    setKeyId(randomId)
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setPopupVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const isExternalEmojis = true

  return (
    <>
      <div className={cn(styles.loadBtn, styles.wrapper)}>
        <CameraButton size={size} onClick={onOpenPopup} />
        <div
          ref={popupRef}
          className={cn(styles.popup, {
            [styles.popup__visible]: isPopupVisible,
          })}
        >
          <div className={styles.popup__item} onClick={handleShowModal}>
            <IconPhoto />
            <p>{t('peerNfts')}</p>
          </div>
          <div className={styles.popup__item}>
            <label htmlFor="avatar-input">
              <IconProfile />
              {t('uploadPhoto')}
              <input
                ref={childRef}
                key={keyId}
                type="file"
                id="avatar-input"
                onChange={handleSelectFile}
                accept="image/png, image/gif, image/jpeg"
              />
            </label>
          </div>
        </div>
      </div>
      {(!isEmojiOrWonkaDataWhileFetching && isEmojiOrWonkaData) || isExternalEmojis ? (
        <PlayaNFTs
          data={items}
          isLoadingImages={isLoadingImages}
          open={showPFPModal}
          onClose={handleCancelModal}
          fetchData={fetchData}
          headers={headers}
        />
      ) : (
        !isEmojiOrWonkaDataWhileFetching && (
          <EmptyNFTsData open={showPFPModal} onClose={handleCancelModal} />
        )
      )}
    </>
  )
}

const ForwardedPopup = forwardRef(Popup)
const MemoizedPopup = React.memo(ForwardedPopup)

export default MemoizedPopup
