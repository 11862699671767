import { TEmojiMedia } from '@core/redux/emojis/types'

export enum IPlayaTypes {
  externalEmoji = 'externalEmoji',
  emoji = 'emoji',
  wonka = 'wonka',
}

export interface ITPlayaItem {
  children: string
  key: IPlayaTypes
  href?: string
}

interface ITokenEmojiType {
  aasm_state: string
  category_name: string
  category_position: number
  code: string
  kind: string
  media: {
    url: string
    small: {
      url: string
    }
    medium: {
      url: string
    }
    large: {
      url: string
    }
  }
  name: string
  price_usd: string
  price_vine: string
  token_group: string
  token_type: string
  trending: number
}
export interface IEmojiType {
  balance: string
  locked: string
  token: ITokenEmojiType
}

export interface IWonkaType {
  code: string
  created_at: string
  currency: string
  description: string
  media: TEmojiMedia
  mint_txid: string
  minting_type: string
  name: string
  price_usd: string
  revealed: boolean
  status: string
  tid: string
  updated_at: string
}

export interface IExternalEmojiType {
  address: string
  contract_address: string
  contract_type: string
  media: {
    display_picture: {
      url: string
    }
    dpr: {
      url: string
    }
    large: {
      url: string
    }
    small: {
      url: string
    }
    thumbnail: {
      url: string
    }
    url: string
  }
  name: string
  symbol: string
  token_id: string
  volume: number
}

export interface IDataType {
  emoji?: IEmojiType[]
  wonka?: IWonkaType[]
  externalEmoji?: IExternalEmojiType[]
}

export interface IHeadersType {
  emoji: {
    total: string
  }
  wonka: {
    total: string
  }
  externalEmoji?: {
    total: string
  }
}
