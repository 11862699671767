const desktop = {
  withoutAuth: {
    auth: '/auth',
    authPopup: '/auth_popup',
    about: '/about',
    blue: '/blue',
    search: '/search-moji',
    terms: '/terms-of-service',
    privacy: 'privacy-policy',
    help: '/help',
    comingq4: '/comingq4',
    reset_password: '/reset_password/:token',
  },
  withAuth: {
    home: '/',
    moji: '/moji',
    selected: '/selected',
    transactionError: '/transaction-error',
    entry: '/entry',
    checkout: '/checkout',
    profile: {
      purchased: {
        username: {
          path: '/profile/:username?',
          url: (username: string) => `/profile/${username}`,
        },
      },
      transactions: '/profile/transactions',
      walletNFTAssets: '/profile/wallet-nft-assets',
      settings: '/profile/settings',

      // TODO: old routs

      dashboard: '/profile/dashboard',
      collected: '/profile/dashboard/collected',
      wallet: {
        main: '/profile/wallet',
        swap: '/profile/wallet/swap',
        withdraw: '/profile/wallet/withdraw',
      },
      trade: '/profile/trade',
      help: '/profile/help',
      kyc: {
        email: '/profile/settings/kyc/email',
        id: '/profile/settings/kyc/id',
        phone: '/profile/settings/kyc/phone',
        profile: '/profile/settings/kyc/profile',
      },
    },
    mint: {
      mintPay: '/wonka/checkout',
    },
    paymentReveal: '/wonka/reveal',
    // TODO: need to remove after implemented profile panel
    transaction: '/transaction',
  },
  test: '/test',
}

export default desktop
