import { takeLatest, all, put, call } from 'typed-redux-saga'

import { API } from '@core/api'
import { API_URLS } from '@core/api/apiUrls'

import { ApiVersion } from '@core/api/types'
import { Action } from 'redux-saga'
import {
  fetchCurrenciesSuccess,
  fetchCurrenciesFailure,
  updateCurrenciesPrice,
  fetchCurrencies,
} from './actions'
import { COMMON_ACTION_TYPES } from './types'

const peatioConfig = {
  apiVersion: ApiVersion.peatio,
}

function* fetchCurrenciesSaga(action: Action) {
  if (fetchCurrencies.match(action)) {
    try {
      const currencies = yield* call(API.get(peatioConfig), API_URLS.public.currencies)

      yield* put(fetchCurrenciesSuccess(currencies))
      const prices = currencies.reduce(
        (acc, item) => ({ ...acc, [item.id]: Number(item.price) }),
        {},
      )
      yield put(updateCurrenciesPrice(prices))
    } catch (err) {
      yield put(fetchCurrenciesFailure())
    }
  }
}

export function* rootSagaCommon() {
  yield all([takeLatest(COMMON_ACTION_TYPES.FETCH_CURRENCIES_REQUEST, fetchCurrenciesSaga)])
}
