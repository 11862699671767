import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import { sentryErrorHandler } from '@utils/errors'

import { addListener, configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

export const sagaMiddleware = createSagaMiddleware({
  onError: sentryErrorHandler,
})
const listenerMiddleware = createListenerMiddleware()

const middlewares = [sagaMiddleware]

export const configureAppStore = (preloadedState?: unknown) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .prepend(listenerMiddleware.middleware)
        .concat(middlewares),
    preloadedState,
  })
  const persistor = persistStore(store)
  return { store, persistor }
}
export const { store, persistor } = configureAppStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const addAppListener = addListener.withTypes<RootState, AppDispatch>()
