import { IApiConfig } from '@core/api/types'
import { makeRequest } from './requestBuilder'

export * from './config'

export const API = {
  get:
    <R = any>(config: IApiConfig) =>
    async (url: string) =>
      makeRequest<R>(
        {
          method: 'get',
          url,
        },
        config,
      ),

  post:
    <R = any>(config: IApiConfig) =>
    async (url: string, body: unknown = {}) =>
      makeRequest<R>(
        {
          method: 'post',
          body,
          url,
        },
        config,
      ),

  patch:
    <R = any>(config: IApiConfig) =>
    async (url: string, body: unknown = {}) =>
      makeRequest<R>(
        {
          method: 'patch',
          body,
          url,
        },
        config,
      ),

  put:
    <R = any>(config: IApiConfig) =>
    async (url: string, body: unknown = {}) =>
      makeRequest<R>(
        {
          method: 'put',
          body,
          url,
        },
        config,
      ),

  delete:
    <R = any>(config: IApiConfig) =>
    async (url: string, body: unknown = {}) =>
      makeRequest<R>(
        {
          method: 'delete',
          body,
          url,
        },
        config,
      ),
}
