import { combineReducers } from '@reduxjs/toolkit'
import profileReducer from './profile/reducer'
import commonReducer from './common/reducer'
import authReducer from './auth/reducer'
import purchasedTokensReducer from './purchasedTokens/reducer'

const rootReducer = combineReducers({
  profile: profileReducer,
  common: commonReducer,
  auth: authReducer,
  purchasedTokens: purchasedTokensReducer,
})

export default rootReducer
