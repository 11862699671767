import React, { useEffect, useState } from 'react'
// import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { TNullable } from 'types/utilityTypes'
import Button from '@components/UiKit/ButtonNew/Button'
import { URLS } from '@constants/urls'
import { TermsOfUseVersion } from '@core/api/config'

import styles from './styles.module.scss'
import { setCookiesPermissions } from './CookiesDisclaimer.utils'

const localStorageTermVersionKey = '__termsOfUseVersion__'

function CookiesDisclaimer() {
  const [render, setRender] = useState(true)
  const [show, setShow] = useState(false)
  const { t } = useTranslation('nsCommon')

  useEffect(() => {
    let timer: TNullable<ReturnType<typeof setTimeout>> = null

    timer = setTimeout(() => {
      const localStorageTermVersion = localStorage.getItem(localStorageTermVersionKey)
      if (String(TermsOfUseVersion) !== localStorageTermVersion) {
        setShow(true)
      }
    }, 3000)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  const handleToggle = (value: boolean) => {
    setRender(false)
    setCookiesPermissions(value)
    localStorage.setItem(localStorageTermVersionKey, String(TermsOfUseVersion))
    setTimeout(() => {
      setShow(false)
    }, 750)
  }

  if (!show) {
    return null
  }

  return (
    <div
      className={cn(styles.container, {
        [styles.animateUp]: render,
        [styles.animateDown]: !render,
      })}
    >
      <div className={styles.inner}>
        <div className={styles.text}>
          {t('cookiesDisclaimer')}{' '}
          <a href={URLS.TERMS_OF_SERVICE} target="_blank" rel="noreferrer">
            {t('cookiePolicy')}
          </a>{' '}
          {t('and')}{' '}
          <a href={URLS.PRIVACY_POLICY} target="_blank" rel="noreferrer">
            {t('privacy')}
          </a>{' '}
          {t('forMoreInformation')}.
        </div>
        <div className={styles.controls}>
          <Button
            variant="secondary"
            onClick={() => {
              handleToggle(false)
            }}
          >
            {t('rejectCookies')}
          </Button>
          <Button
            onClick={() => {
              handleToggle(true)
            }}
          >
            {t('acceptCookies')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CookiesDisclaimer)
