/* eslint-disable max-len */

import { stripeErrorMessages } from './stripeErrorMessages'

const errorMessages = {
  account: {
    stripe: {
      invalid_request: 'Stripe Invalid Request',
      invalid: {
        source_currency: 'Invalid Currency',
      },
      create_error: 'Stripe Create Error',
    },
    purchase: {
      age_restricted: 'User Age Restricted',
      invalid_currency: 'Invalid Currency',
      limit_exceeded: 'Purchase Limit of token exceeded',
      non_positive_volume: 'Negative Volume',
    },
    token: {
      non_positive_volume: 'Negative Volume',
    },
    banxa: {
      disclaimer: {
        not_accepted: 'Banxa Disclaimer Not Accepted',
      },
      order: {
        create_error: 'Order create error',
      },
    },
  },
  admin: {
    order: {
      non_positive_origin_volume: 'Stripe Invalid Request',
    },
  },
  accounts: {
    nft: {
      origin_volume: {
        non_decimal_price: 'Non Decimal Price',
      },
      currency_doesnt_exist: 'Currency does not exist',
      token_group: {
        invalid: 'Invalid Token Group',
      },
    },
    badges: {
      currency_doesnt_exist: 'Invalid Currency Code',
    },
  },
  authz: {
    invalid_session: 'Session expired, please log in',
  },
  token: {
    token_group: {
      not_permitted: 'Token Group is not permitted',
    },
    unlock_method: {
      not_permitted: 'Unlock method is not permitted',
    },
    disabled_or_completed: 'Token Swap is disabled or completed',
    invalid: {
      token_group: 'Invalid Token Group',
    },
    country: {
      not_permitted: 'Country not permitted',
    },
    purchase: {
      limit_exceeded: 'Purchase Limit of token exceeded',
      non_positive_volume: 'Negative Volume value',
    },
    cart_item: {
      create_error: 'Cart Item create error',
      doesnt_exist: 'Item does not exist',
    },
    swap: {
      available_balance: 'You don`t have enough assets for this operation',
      create_error: 'Error while creating token_swap',
      disabled_or_completed: 'Token Swap is disabled or completed',
      invalid: {
        purchase_type: 'Invalid Payment Type',
      },
    },
    low_volume: 'Low Volume',
    cart: {
      currency: {
        doesnt_exist: 'Invalid Currency Code',
      },
      is_empty: 'Cart is empty',
    },
    volume: {
      non_integer_amount: 'Non Integer Volume',
    },
    payment_gateway: {
      disabled_or_not_exist: 'Token payment gateway is disabled or it does not exist',
    },
  },
  resource: {
    identity: {
      invalid_parameter: 'invalid params',
    },
    phone: {
      invalid_channel: 'Invalid sms channel [call, sms]',
    },
    otp: {
      invalid: 'Invalid OTP code',
    },
    user: {
      invalid_email: 'Invalid email',
      resend_time: `You can try in 1 minute`,
      cant_be_deleted: 'This user cant be deleted',
      verification_invalid: 'Invalid OTP code',
      code_is_expired: 'OTP code is expired',
      update_error: 'Error while updating profile',
      delete_response_error: 'User delete error',
      delete_error: 'User delete error',
    },
    users: {
      invalid_num: 'Invalid phone number format',
    },
    password: {
      prev_pass_not_correct: 'Old password is not correct',
    },
    session: {
      code_is_expired: 'Code is expired, please send a new one',
      verification_invalid: 'The code you entered is incorrect',
    },
    media: {
      update_error: 'Media update error',
      response_error: 'Token media response error',
      create_error: 'Error while creating media asset',
    },
    wallet: {
      already_exist: 'Wallet address is used by another account',
    },
  },
  user: {
    update: {
      invalid_state: 'User invalid state',
    },
    email: {
      resend_time: `You can try in 1 minute`,
      invalid: 'Invalid email',
    },
    phone_number: {
      resend_time: `You can try in 1 minute`,
    },
  },
  phone_number: {
    taken: 'Phone number is already taken',
  },
  email: {
    taken: 'Email address is already taken',
  },
  users: {
    media: {
      moderation_check_failed: 'The image contains dangerous or violent content',
    },
  },
  username: {
    taken: 'Username is already taken',
  },
  identity: {
    first_name: {
      too_long: 'First name too long',
    },
    last_name: {
      too_long: 'Last name is too long',
    },
    username: {
      too_long: 'User name is too long',
    },
    captcha: {
      required: 'Captcha is required',
      verification_failed: 'Captcha verification failed,',
    },
    platform: {
      invalid_platform: 'Invalid platform provided',
    },
    session: {
      email: {
        not_verified: 'user email is not verified',
      },
      not_found: 'User does not exist',
      missing_otp: 'Otp code is not present',
      invalid_identifier: 'Invalid identifier provided',
      invalid_captcha_format: 'invalid captcha format',
      invalid_otp: 'Invalid Code',
      invalid_params: 'Please Enter Valid Details',
      invalid_details: 'Please Enter Valid Details',
      banned: 'Your account has been in a banned state',
      reached_max_login_attempts:
        'Maximum login attempts exceeded. For security purposes, login has been disabled for 4 hrs. Please try again later',
      resend_time: 'Please try after one minute',
      too_many_resend: 'Too many otp requested',
    },
    user: {
      already_registered: 'Phone number is already registered',
      utilized_token: 'JWT has already been used',
      passwords_doesnt_match: 'Password mismatch',
      missing_confirm_password: 'Confirm password is missing',
      missing_password: 'Password is missing',
      missing_pass_token: 'Reset password token is missing',
      email_invalid_or_doesnt_exist: 'Email is invalid or does not exist',
      username_doesnt_exist_or_invalid: 'Username does not exist or is invalid',
      invalid_email: 'Invalid email provided',
      age_restricted: 'User age restriction',
      doesnt_exist: 'Email is not registered. You will be redirected to the signup.',
      password_disabled: 'User password is disabled',
    },
  },
  other: {
    failed_transaction: 'Something was wrong with the transaction',
    invalid_transaction: 'Could not find the transaction, please check the transaction ID',
    not_image: 'File not image',
    big_size_image: 'Size should be less than 5 Mb',
    kyc_not_completed: 'Complete Your KYC',
    something_went_wrong: 'Something went wrong. Please try again',
    insufficient_funds: 'Insufficient Funds',
    invalid_mnemonics: 'Invalid Mnemonics',
    metamask_not_installed: 'Metamask not installed',
    metamask_is_disconnected: 'Metamask is disconnected',
    please_connect_to: 'Please connect to ',
    add_this_chain_id: 'Add this chain id ',
  },
  banxa: {
    dest_currency: {
      doesnt_exist: 'Invalid Purchase Type value',
    },
    token: {
      not_found: 'Token not found',
    },
    min_amount: {
      not_reached: 'Minimum amount not reached',
    },
    currency: {
      disabled_or_not_found: 'Currency disabled or not found',
    },
  },
  coin: {
    payment_gateway: {
      disabled_or_not_exist: 'Coin payment gateway is disabled or it does not exist',
    },
  },
  wonka: {
    payment_gateway: {
      disabled_or_not_exist: 'Wonka payment gateway is disabled or it does not exist',
    },
  },
  swap: {
    currency: {
      token_swap: {
        disabled: 'Currency Token Swap is not enabled',
      },
    },
    account: {
      does_not_exist: 'Current User does not have account with specified currency',
      insufficient_balance: 'Insufficient balance in account',
    },
    create_error: 'Error while creating token_swap',
    disabled_or_completed: 'Token Swap is disabled or completed',
  },
  crypto: {
    setting: {
      invalid_state: 'Invalid Account Adjustment State',
    },
  },
  member: {
    bonus: {
      invalid_type: 'Invalid Bonus Type',
    },
  },
  default: 'Oops, something went wrong! Check again later!',
  ...stripeErrorMessages,
}

const successMessages = {
  resource: {
    otp: {
      sent: 'OTP code sent',
    },
  },
  other: {
    notifications_updated: 'Email notifications updated successfully',
    profile_updated: 'Profile updated successfully',
    email_updated: 'Email updated successfully.',
    phone_updated: 'Phone number updated successfully.',
    transaction_completed: 'Transaction Completed',
    transaction_successful: 'Transaction Successful',
    transaction_processed: 'Your transaction processing is done. Please check the profile section.',
    password_updated: 'Your password is updated successfully',
  },
}

export { errorMessages, successMessages }
