import { isDev } from '@constants/environments'
import { useEffect, useLayoutEffect, useRef, DependencyList } from 'react'

const useHookOnce = (
  hook: (effect: () => void | undefined, deps?: DependencyList) => void,
  callback: () => void,
  dependencies?: DependencyList,
): void => {
  const shouldSkip = useRef(isDev)
  hook(() => {
    if (shouldSkip.current) {
      shouldSkip.current = false
      return
    }
    callback()
  }, dependencies)
}

export const useLayoutEffectOnce = (callback: () => void, dependencies?: DependencyList): void =>
  useHookOnce(useLayoutEffect, callback, dependencies)

const useEffectOnce = (callback: () => void, dependencies?: DependencyList): void =>
  useHookOnce(useEffect, callback, dependencies)

export default useEffectOnce
