import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { configSelectors } from '@core/redux/selectors'
import { settings } from '@constants/settings'
import { fetchTransactions } from '@core/redux/purchasedTokens/actions'

import { ITransactionTypes, ITtransactionTabItem } from './types'
import { getCurrencyPrecision, getMappedColumns } from './Transaction.utils'
import TransactionsUi from './Transactions'

const { defaultValue: defaultSize } = settings.size

function Transactions() {
  const currencies = useSelector(configSelectors.currencies)
  const transactions = useSelector(configSelectors.transactions)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(defaultSize)
  const [transactionType, setTransactionType] = useState<ITransactionTypes>(
    ITransactionTypes.recentActivities,
  )

  const { items, total, loading } = useMemo(() => {
    const currentTransaction = transactions?.[transactionType]
    const items = currentTransaction?.data?.items ?? null
    const total = currentTransaction?.data?.total ?? 0
    const loading = !items || currentTransaction?.loading

    return {
      items,
      total,
      loading,
    }
  }, [transactions, transactionType])

  const dispatch = useDispatch()

  const { t } = useTranslation('nsUserProfile')

  const columnsNames = t('columnsNames', { returnObjects: true })

  const getTransaction = useCallback(() => {
    dispatch(
      fetchTransactions({
        transactionType,
        params: {
          page,
          limit,
        },
      }),
    )
  }, [page, limit, transactionType])

  useEffect(() => {
    getTransaction()
  }, [getTransaction])

  const currencyPrecision = useMemo(() => getCurrencyPrecision(currencies), [currencies])

  useEffect(() => {
    if (items) {
      window?.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [items])

  const handlePageChange = (currentPage) => {
    setPage(currentPage.selected)
  }

  const handleSizeChange = (size) => {
    setLimit(Number(size.value))
    setPage(0)
  }

  const mappedColumns = useMemo(
    () =>
      getMappedColumns({
        currencyPrecision,
        currencies,
        columnsNames,
      }),
    [currencies, currencyPrecision, columnsNames],
  )

  const onChangeTab = useCallback((item: ITtransactionTabItem) => {
    setTransactionType(item.type)
    setPage(0)
  }, [])

  return (
    <TransactionsUi
      transactionType={transactionType}
      onChangeTab={onChangeTab}
      getTransaction={getTransaction}
      mappedColumns={mappedColumns}
      items={items}
      loading={loading}
      total={total}
      limit={limit}
      handlePageChange={handlePageChange}
      page={page}
      handleSizeChange={handleSizeChange}
    />
  )
}

export default memo(Transactions)
