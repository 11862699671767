import storage from 'redux-persist/lib/storage'
import encryptor from '@core/encryptor'
import { persistReducer } from 'redux-persist'
import { createReducer } from '@reduxjs/toolkit'
import { AuthPages } from '@containers/Auth/types'
import {
  fetchCheckIfUsernameAvailableFailure,
  fetchCheckIfUsernameAvailableRequest,
  fetchCheckIfUsernameAvailableSuccess,
  goBackAuthStep,
  goForwardToAuthStep,
  setAuthFields,
  setAuthLoading,
  setAuthStep,
  setCaptcha,
} from './actions'

const initialState = {
  steps: [] as AuthPages[],
  loading: false,
  userFields: {
    email: '',
    phone: '',
  },
  userName: {
    loading: false,
    isAvailable: false,
  },
  captcha: '',
}

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCheckIfUsernameAvailableRequest, (state) => ({
      ...state,
      userName: {
        ...initialState.userName,
        loading: true,
      },
    }))
    .addCase(fetchCheckIfUsernameAvailableFailure, (state) => ({
      ...state,
      userName: {
        ...initialState.userName,
      },
    }))
    .addCase(fetchCheckIfUsernameAvailableSuccess, (state) => ({
      ...state,
      userName: {
        isAvailable: true,
        loading: false,
      },
    }))
    .addCase(setAuthLoading, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setAuthFields, (state, action) => ({
      ...state,
      userFields: {
        ...state.userFields,
        ...action.payload,
      },
    }))
    .addCase(setCaptcha, (state, action) => ({
      ...state,
      captcha: action.payload,
    }))
    .addCase(setAuthStep, (state, action) => ({
      ...state,
      steps: [action.payload],
    }))
    .addCase(goForwardToAuthStep, (state, action) => ({
      ...state,
      steps: [...state.steps, action.payload],
    }))
    .addCase(goBackAuthStep, (state) => ({
      ...state,
      steps: state.steps.slice(0, -1),
    }))
})

const authConfig = {
  key: 'peer_auth',
  storage,
  transforms: [encryptor],
  blacklist: ['loading'],
}

export default persistReducer<typeof initialState>(authConfig, authReducer)
