import React, { memo } from 'react'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'

import noDataImg from '@assets/images/profile/nftPFPs.png'

import styles from './style.module.scss'

function ProfilePurchasedNoData() {
  const { t } = useTranslation('nsUserProfile')

  return (
    <div className={styles.container} data-testid="purchased-no-data">
      <div className={styles.banner}>
        <img className={styles.img} src={noDataImg} width={700} alt="" />
      </div>
      <p className={styles.title}>{t('showcaseYourOwnNftsAndPfps')}</p>
    </div>
  )
}

export default memo(ProfilePurchasedNoData, isEqual)
