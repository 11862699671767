/* eslint-disable max-len */
import React from 'react'
import cls from 'classnames'
import { ICustomIconProps } from 'types/common'
import styles from './styles.module.scss'

function IconEye({ hovered, width = '24' }: Readonly<ICustomIconProps>) {
  return (
    <svg
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cls({ [styles.hovered]: hovered })}
    >
      <g id="Eye">
        <path
          id="Vector"
          d="M23.5299 11.5434C23.4952 11.4675 22.674 9.64406 20.8599 7.83C18.4327 5.40656 15.3737 4.125 11.9987 4.125C8.62366 4.125 5.5646 5.40656 3.14022 7.83C1.32616 9.64406 0.504909 11.4675 0.467409 11.5434C0.403896 11.6874 0.371094 11.8431 0.371094 12.0005C0.371094 12.1579 0.403896 12.3135 0.467409 12.4575C0.502097 12.5344 1.32335 14.3569 3.13835 16.1709C5.5646 18.5944 8.62366 19.875 11.9987 19.875C15.3737 19.875 18.4327 18.5944 20.8562 16.1709C22.6712 14.3569 23.4924 12.5344 23.5271 12.4575C23.5911 12.3137 23.6243 12.1581 23.6248 12.0008C23.6253 11.8434 23.593 11.6876 23.5299 11.5434ZM19.2118 14.6372C17.199 16.6191 14.7727 17.625 11.9987 17.625C9.2246 17.625 6.79835 16.6191 4.78835 14.6362C3.99742 13.8537 3.31707 12.9667 2.76616 12C3.31723 11.0337 3.99757 10.1471 4.78835 9.36469C6.79928 7.38094 9.2246 6.375 11.9987 6.375C14.7727 6.375 17.198 7.38094 19.209 9.36469C19.9998 10.147 20.6802 11.0336 21.2312 12C20.6802 12.9666 19.9998 13.8536 19.209 14.6362L19.2118 14.6372ZM11.9987 7.875C11.1828 7.875 10.3853 8.11693 9.70693 8.57019C9.02858 9.02345 8.49987 9.66769 8.18766 10.4214C7.87545 11.1752 7.79376 12.0046 7.95292 12.8047C8.11208 13.6049 8.50495 14.3399 9.08184 14.9168C9.65874 15.4937 10.3937 15.8866 11.1939 16.0457C11.9941 16.2049 12.8235 16.1232 13.5772 15.811C14.331 15.4988 14.9752 14.9701 15.4285 14.2917C15.8817 13.6134 16.1237 12.8158 16.1237 12C16.1224 10.9064 15.6874 9.85787 14.9141 9.08455C14.1408 8.31124 13.0923 7.87624 11.9987 7.875ZM11.9987 13.875C11.6278 13.875 11.2653 13.765 10.957 13.559C10.6486 13.353 10.4083 13.0601 10.2664 12.7175C10.1245 12.3749 10.0873 11.9979 10.1597 11.6342C10.232 11.2705 10.4106 10.9364 10.6728 10.6742C10.9351 10.412 11.2692 10.2334 11.6329 10.161C11.9966 10.0887 12.3736 10.1258 12.7162 10.2677C13.0588 10.4096 13.3516 10.65 13.5577 10.9583C13.7637 11.2666 13.8737 11.6292 13.8737 12C13.8737 12.4973 13.6761 12.9742 13.3245 13.3258C12.9729 13.6775 12.4959 13.875 11.9987 13.875Z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default React.memo(IconEye)
