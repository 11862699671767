import React, { memo, useMemo } from 'react'
import RcTable from 'rc-table'
import cn from 'classnames'
import isEqual from 'react-fast-compare'
import { ColumnsType, DefaultRecordType, TableLayout } from 'rc-table/lib/interface'
import LoaderIcon from '@components/Icons/Loader'
import useCurrentWidth from '@hooks/useCurrentWidth'

import styles from './style.module.scss'
import MobileTableCards from './mobileTableCards'

interface IProps {
  columns: ColumnsType<DefaultRecordType>
  data: DefaultRecordType[]
  loading?: boolean
  emptyText?: string
  tableLayout?: TableLayout
  displayedMobileCards?: boolean
  scroll?: {
    x?: number | true | string
    y?: number | string
  }
  emptyComponent?: JSX.Element
  emptyCellValue?: string
}

function Table({
  columns,
  data,
  loading,
  emptyText = 'No data to display',
  tableLayout = 'auto',
  displayedMobileCards,
  scroll,
  emptyComponent,
  emptyCellValue = '-',
}: Readonly<IProps>) {
  const { isMobile } = useCurrentWidth()

  const preparedColumns = useMemo(
    () =>
      columns.map((item) => {
        const cell = { ...item } as DefaultRecordType

        if (!cell.render) {
          cell.render = (value: DefaultRecordType) => value ?? emptyCellValue
        }
        return cell
      }),
    [emptyCellValue, columns],
  )

  const noData =
    !loading &&
    !data?.length &&
    (emptyComponent || (
      <div data-testid="table-nodata" className={styles.noData}>
        {emptyText}
      </div>
    ))

  return (
    <div className={styles.wrapper}>
      {(isMobile && displayedMobileCards && (
        <div>
          <MobileTableCards data={data} columns={preparedColumns} />
          {!data?.length && noData}
        </div>
      )) || (
        <div className={cn(styles.wrapTable, { [styles['wrapTable--empty']]: !!noData })}>
          <RcTable
            scroll={scroll}
            tableLayout={tableLayout}
            columns={preparedColumns}
            data={data}
            emptyText=""
            rowKey="id"
          />
          {noData}
        </div>
      )}

      {loading && (
        <div
          data-testid="table-loader"
          className={cn(styles.loading, { [styles['loading--static']]: !data?.length })}
        >
          <LoaderIcon />
        </div>
      )}
    </div>
  )
}

export default memo(Table, isEqual)
