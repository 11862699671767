import React, { memo, useCallback, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useDispatch, useSelector } from 'react-redux'
import helpers from '@utils/helpers'
import useEffectOnce from '@hooks/useEffectOnce'
import { TNullable } from 'types/utilityTypes'
import { TEmojiItemPurchased } from '@core/redux/emojis/types'
import { EmojiEvents, eventTrack } from '@utils/analyticsTracker'
import { CurrenciesForGoogleEvents } from '@utils/analyticsTracker/types'
import { configSelectors } from '@core/redux/selectors'
import { setProfileEmojiPicture } from '@core/redux/profile/actions'
import { fetchPurchasedTokens } from '@core/redux/purchasedTokens/actions'

import { getItemOrder, useLimitItem } from './Purchased.utils'
import { DEFAULT_ITEMS_COUNT_PER_PAGE } from './Purchased.config'
import Purchased from './Purchased'

function ProfilePurchased() {
  const [currentCard, setCurrentCard] = useState<TNullable<TEmojiItemPurchased>>(null)
  const [isLargeCard, setIsLargeCard] = useState(false)
  const [currentCardPosition, setCurrentCardPosition] = useState(0)
  const [orderDetails, setOrderDetails] = useState(0)
  const [page, setPage] = useState(0)
  const {
    data: { items, total },
    loading: purchaseLoading,
  } = useSelector(configSelectors.purchasedTokens)

  const dispatch = useDispatch()
  const limit = useLimitItem(isLargeCard)
  const itemsCountPerPage = limit || DEFAULT_ITEMS_COUNT_PER_PAGE
  const loading = !items || purchaseLoading
  const noData = !(loading || items?.length)

  useEffectOnce(() => {
    if (itemsCountPerPage > 0) {
      dispatch(
        fetchPurchasedTokens({
          params: {
            limit: itemsCountPerPage,
            page: page + 1,
          },
        }),
      )
    }
  }, [itemsCountPerPage, page, itemsCountPerPage])

  useEffectOnce(() => {
    if (items?.length) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [items])

  const handlePageChange = (currentPage: { selected: number }) => {
    setCurrentCard(null)
    setPage(currentPage.selected)
  }

  useEffect(() => {
    const func = () => setOrderDetails(getItemOrder(currentCardPosition, isLargeCard))
    func()
    window.addEventListener('resize', func)

    return () => {
      window.removeEventListener('resize', func)
    }
  }, [currentCardPosition, isLargeCard])

  const onClickCard = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: TEmojiItemPurchased,
    order: number,
  ) => {
    setCurrentCard(item)
    setCurrentCardPosition(order)
    const target = e.currentTarget

    eventTrack(
      EmojiEvents.viewEmoji({
        currency: CurrenciesForGoogleEvents.USD,
        value: currentCard?.token.price_usd,
      }),
    )

    setTimeout(() => {
      const coords = helpers.getPositionElement(target)

      window.scrollTo({
        top: coords.y - 90,
        behavior: 'smooth',
      })
    }, 100)
  }

  const onSetHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, code: string) => {
      event.stopPropagation()
      const formData = new FormData()
      formData.append('token', code)
      dispatch(setProfileEmojiPicture(formData))
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 100)
    },
    [],
  )

  return (
    <Purchased
      loading={loading}
      items={items}
      isLargeCard={isLargeCard}
      currentCard={currentCard}
      onClickCard={onClickCard}
      orderDetails={orderDetails}
      onSetHandler={onSetHandler}
      setCurrentCard={setCurrentCard}
      setIsLargeCard={setIsLargeCard}
      itemsCountPerPage={itemsCountPerPage}
      total={total}
      handlePageChange={handlePageChange}
      page={page}
      noData={noData}
    />
  )
}

export default memo(ProfilePurchased, isEqual)
