import React, { memo, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { DefaultRecordType as IDefaultRecordType } from 'rc-table/lib/interface'

import styles from './style.module.scss'

interface IProps {
  columns: any
  data: IDefaultRecordType[]
}

function MobileTableCards({ columns, data }: IProps) {
  const mappedData = useMemo(
    () =>
      data?.map((item) =>
        columns?.map(({ dataIndex, render, title, mobileWidth, key }, i) => {
          let content = dataIndex ? item[dataIndex] : item

          if (render) {
            content = render(content)
          }

          return {
            title,
            width: mobileWidth,
            key: key || crypto?.randomUUID() || i,
            content,
          }
        }),
      ) || [],
    [data, columns],
  )

  return (
    <div>
      {mappedData?.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className={styles.card}>
          {item.map(({ title, content, key }, i) => (
            <div key={`${key}-${i + String(title + key).length}`} className={styles.card__item}>
              <div className={styles.card__item_title}>{title}</div>
              <div className={styles.card__item_content}>{content}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default memo(MobileTableCards, isEqual)
