import { ApiVersion, IApiConfig } from '@core/api/types'
import { IEnv } from '../../env/types'

export const defaultEnv: IEnv = {
  title: 'Playa',
  minutesUntilAutoLogout: '60',
  withCredentials: true,
  defaultStorageLimit: 50,
  chainid: 11155111,
  defaultCurrency: 'vne',
  defaultCurrencyName: 'Vine',
  googleKey: 'G-45SD36KSJX',
  termsOfUseVersion: 1,
  walletConnectProjectID: 'ea4fe94a9e89ff0df5ca5bc4eb5486ad',
  refid: '',
  removePeer: 'true',
  peerclubUrl: 'https://pc.peerclub.com',
  //
  captcha: {
    captchaType: 'turnstile',
    siteKey: '0x4AAAAAAADQoMhQrjV710_v',
    key: '',
  },
  //
  stripeKey: '',
  //
  sentryKey: '',
  //
  baseUrl: '',
  api: {
    authUrl: '',
    tradeUrl: '',
    applogicUrl: '',
    rangerUrl: 'wss://swap.peerclub.com/api/v2/ranger',
    aliasUrl: '',
    jumioUrl: '',
    url: '',
  },
}

export const Frontend: { config: IEnv } = {
  config: defaultEnv,
}

window.env = window.env || defaultEnv
Frontend.config = { ...window.env }

Frontend.config.captcha = Frontend.config.captcha || defaultEnv.captcha

export const baseUrl = () => Frontend.config.baseUrl
export const authUrl = () => Frontend.config.api.authUrl
export const tradeUrl = () => Frontend.config.api.tradeUrl
export const applogicUrl = () => Frontend.config.api.applogicUrl
export const rangerUrl = () => Frontend.config.api.rangerUrl
export const aliasUrl = () => Frontend.config.api.aliasUrl

export const minutesUntilAutoLogout = () => Frontend.config.minutesUntilAutoLogout || '5'
export const withCredentials = () => Frontend.config.withCredentials

export const siteKey = () => Frontend.config.captcha.siteKey
export const captchaType = () => Frontend.config.captcha.captchaType

export const defaultStorageLimit = () => Frontend.config.defaultStorageLimit
export const title = () => Frontend.config.title
export const chainid = () => Frontend.config.chainid

export const defaultCurrencyId = Frontend.config.defaultCurrency.toLowerCase()
export const defaultCurrency =
  Frontend.config.defaultCurrencyName || Frontend.config.defaultCurrency.toUpperCase()

export const TermsOfUseVersion = Frontend.config.termsOfUseVersion

export const BarongConfig: IApiConfig = {
  apiVersion: ApiVersion.barong,
}
