import { takeLatest, all, put, call } from 'typed-redux-saga'
import type { PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { successMessages } from '@constants/index'
import { API } from '@core/api'
import { API_URLS } from '@core/api/apiUrls'
import { errorsHandler } from '@core/helper'
import { ApiVersion, IProfileModel, IUserModel, TEmailNotificationItem } from '@core/api/types'
import { eventTrack, Web3WalletEvents } from '@utils/analyticsTracker'
import i18next from 'i18next'
import queryString from 'query-string'
import { TNFTTokenData } from '@components/Profile/WalletAssets'
import { PROFILE_TYPES } from './types'
import {
  fetchProfileFailure,
  fetchProfileSuccess,
  fetchProfile,
  modifyProfileFailure,
  modifyProfileSuccess,
  fetchSendOTPFailure,
  fetchSendOTPSuccess,
  fetchDeleteAccOTPFailure,
  fetchDeleteAccOTPSuccess,
  fetchDeleteAccountSuccess,
  fetchDeleteAccountFailure,
  fetchGetNotificationsSuccess,
  fetchGetNotificationsFailure,
  fetchSetNotificationsFailure,
  fetchSetNotificationsSuccess,
  setProfileEmojiPicture,
  setProfileWonkaPicture,
  fetchDeleteAccountRequest,
  fetchSetNotificationsRequest,
  uploadProfilePicture,
  modifyProfile,
  setWalletRequest,
  setProfileExternalNFTPicture,
  fetchExternalNFTListSuccess,
  fetchExternalNFTList,
  setWalletFailure,
  setProfileLoading,
  setUploadLoader,
  fetchUpdateProfilePhoneNumberSuccess,
  fetchUpdateProfilePhoneNumberRequest,
  fetchVerifyProfilePhoneNumberRequest,
  fetchVerifyProfilePhoneNumberSuccess,
  fetchUpdateProfileEmailRequest,
  fetchUpdateProfileEmailSuccess,
  fetchVerifyProfileEmailRequest,
  fetchVerifyProfileEmailSuccess,
} from './actions'

const userConfig = {
  apiVersion: ApiVersion.barong,
}

function* fetchSendWalletSaga(action: PayloadAction) {
  if (setWalletRequest.match(action)) {
    try {
      yield* call(API.post<void>(userConfig), API_URLS.resource.users.wallet, action.payload)
      yield* call(
        eventTrack,
        Web3WalletEvents.addressVerified({ address: action.payload.wallet_details.address }),
      )
      yield* call([toast, 'success'], i18next.t('walletConnected', { ns: 'nsMessages' }))
      yield* put(fetchProfile())
    } catch (err) {
      if (err?.message?.includes('resource.wallet.already_exist')) {
        errorsHandler(err)
        yield* put(setWalletFailure())
      }
    }
  }
}

function* fetchProfileSaga() {
  try {
    const user = yield* call(
      API.get<IUserModel>(userConfig),
      `${API_URLS.resource.users.me}?date=${new Date().getTime()}`,
    )
    yield* put(fetchProfileSuccess(user))
  } catch (err) {
    yield* put(fetchProfileFailure())
  }
}

function* profilePhotoUploadSaga(action: PayloadAction) {
  if (uploadProfilePicture.match(action)) {
    try {
      yield* call(API.post(userConfig), API_URLS.resource.users.media, action.payload)
      yield* put(fetchProfile())
      yield put(setUploadLoader(false))
      yield* call([toast, 'success'], 'Photo uploaded successfully.')
    } catch (err) {
      yield put(setUploadLoader(false))
      yield* call(errorsHandler, err)
    }
  }
}

function* profileDeletePhotoSaga() {
  try {
    yield* call(API.delete<void>(userConfig), API_URLS.resource.users.removeMedia)
    yield* put(fetchProfile())
    yield put(setUploadLoader(false))

    yield* call([toast, 'success'], 'Photo removed successfully.')
  } catch (err) {
    yield* call(errorsHandler, err)
  }
}

function* modifyUser(action: PayloadAction) {
  if (modifyProfile.match(action)) {
    try {
      yield put(setProfileLoading(true))
      const updatedData = yield* call(
        API.post<IUserModel>(userConfig),
        'resource/users/update',
        action.payload,
      )
      yield put(fetchProfileSuccess(updatedData))
      yield put(modifyProfileSuccess())
      yield call([toast, 'success'], successMessages.other.profile_updated)
    } catch (err) {
      yield put(modifyProfileFailure())
      errorsHandler(err)
    } finally {
      yield put(setProfileLoading(false))
    }
  }
}

function* logoutSaga() {
  try {
    yield* call(API.delete<void>(userConfig), API_URLS.identity.sessions.index)
    yield* put(fetchProfile())
  } catch (err) {
    yield* call(errorsHandler, err)
  }
}

function* profileSetEmojiAsPhotoSaga(action: PayloadAction) {
  if (setProfileEmojiPicture.match(action)) {
    try {
      yield* call(API.post<void>(userConfig), API_URLS.resource.users.mediaEmoji, action.payload)
      yield* put(fetchProfile())

      yield* call([toast, 'success'], 'Emoji uploaded successfully.')
    } catch (err) {
      yield* call(errorsHandler, err)
    } finally {
      yield put(setUploadLoader(false))
    }
  }
}

function* profileSetExternalNFTAsPhotoSaga(action: PayloadAction) {
  if (setProfileExternalNFTPicture.match(action)) {
    try {
      yield call(
        API.post<void>(userConfig),
        API_URLS.resource.users.mediaExternalNft,
        action.payload,
      )
      yield put(fetchProfile())

      yield call([toast, 'success'], 'Token media uploaded successfully.')
    } catch (err) {
      errorsHandler(err)
    } finally {
      yield put(setUploadLoader(false))
    }
  }
}

function* profileSetWonkaAsPhotoSaga(action: PayloadAction) {
  if (setProfileWonkaPicture.match(action)) {
    try {
      yield* call(API.post<void>(userConfig), API_URLS.resource.users.mediaWonka, action.payload)
      yield* put(fetchProfile())
      yield* call([toast, 'success'], 'Wonka uploaded successfully.')
    } catch (err) {
      yield* call(errorsHandler, err)
    } finally {
      yield put(setUploadLoader(false))
    }
  }
}

function* profileUpdateEmailSaga(action: PayloadAction) {
  if (fetchUpdateProfileEmailRequest.match(action)) {
    try {
      yield* call(
        API.post<IProfileModel>(userConfig),
        API_URLS.resource.users.updateEmail,
        action.payload,
      )
      yield* put(fetchProfile())
      yield* put(fetchUpdateProfileEmailSuccess())
      yield* call([toast, 'success'], successMessages.resource.otp.sent)
    } catch (err) {
      yield* call(errorsHandler, err)
    }
  }
}

function* profileVerifyEmailSaga(action: PayloadAction) {
  if (fetchVerifyProfileEmailRequest.match(action)) {
    try {
      yield* call(API.post(userConfig), API_URLS.resource.users.verifyEmail, action.payload)
      yield* put(fetchProfile())
      yield* put(fetchVerifyProfileEmailSuccess())
      yield* call([toast, 'success'], successMessages.other.email_updated)
    } catch (err) {
      yield* call(errorsHandler, err)
    }
  }
}

function* profileUpdatePhoneNumberSaga(action: PayloadAction) {
  if (fetchUpdateProfilePhoneNumberRequest.match(action)) {
    try {
      yield* call(
        API.post<IProfileModel>(userConfig),
        API_URLS.resource.users.updatePhoneNumber,
        action.payload,
      )
      yield* put(fetchProfile())
      yield* put(fetchUpdateProfilePhoneNumberSuccess())
    } catch (err) {
      yield* call(errorsHandler, err)
    }
  }
}

function* profileVerifyPhoneNumberSaga(action: PayloadAction) {
  if (fetchVerifyProfilePhoneNumberRequest.match(action)) {
    try {
      yield* call(
        API.post<IProfileModel>(userConfig),
        API_URLS.resource.users.verifyPhoneNumber,
        action.payload,
      )
      yield* put(fetchProfile())
      yield* put(fetchVerifyProfilePhoneNumberSuccess())
      yield* call([toast, 'success'], successMessages.other.phone_updated)
    } catch (err) {
      yield* call(errorsHandler, err)
    }
  }
}

function* sendOTP() {
  try {
    yield* call(API.post<void>(userConfig), 'resource/user/send-otp')
    yield* put(fetchSendOTPSuccess())
  } catch (err) {
    yield* put(fetchSendOTPFailure())
    yield* call(errorsHandler, err)
  }
}

function* sendDeleteAccOTP() {
  try {
    yield* call(API.post<void>(userConfig), 'resource/user/send-otp')
    yield* put(fetchDeleteAccOTPSuccess())
  } catch (err) {
    yield* put(fetchDeleteAccOTPFailure())
    yield* call(errorsHandler, err)
  }
}

function* deleteAccount(action: PayloadAction) {
  if (fetchDeleteAccountRequest.match(action)) {
    try {
      yield* call(API.delete<void>(userConfig), 'resource/user/delete-account', action.payload)
      yield* put(fetchDeleteAccountSuccess())
    } catch (err) {
      if (err?.message?.[0] === 'resource.user.cant_be_deleted') {
        yield* put(fetchDeleteAccountFailure())
      } else {
        yield* call(errorsHandler, err)
      }
    }
  }
}

function* getNotifications() {
  try {
    const data = yield* call(
      API.get<TEmailNotificationItem[]>(userConfig),
      API_URLS.resource.email.notifications,
    )
    yield* put(fetchGetNotificationsSuccess(data))
  } catch (err) {
    yield* put(fetchGetNotificationsFailure())
  }
}

function* setNotifications(action: PayloadAction) {
  if (fetchSetNotificationsRequest.match(action)) {
    try {
      yield* call(API.post<void>(userConfig), API_URLS.resource.email.notifications, action.payload)
      yield* put(fetchSetNotificationsSuccess(action.payload))
      yield* call([toast, 'success'], successMessages.other.notifications_updated)
    } catch (err) {
      yield* put(fetchSetNotificationsFailure())
    }
  }
}

function* fetchExternalNFTs(action: PayloadAction) {
  if (fetchExternalNFTList.match(action)) {
    try {
      const url = queryString.stringifyUrl({
        url: API_URLS.resource.users.nftDetails,
        query: action.payload ?? {},
      })
      const data = yield call(API.get<TNFTTokenData[]>(userConfig), url)
      yield put(fetchExternalNFTListSuccess(data))
    } catch (err) {
      errorsHandler(err)
    }
  }
}

export function* rootSagaProfile() {
  yield* all([
    takeLatest(PROFILE_TYPES.FETCH_PROFILE_REQUEST, fetchProfileSaga),
    takeLatest(PROFILE_TYPES.UPDATE_USER, modifyUser),

    takeLatest(PROFILE_TYPES.UPDATE_PROFILE_REQUEST, modifyUser),

    takeLatest(PROFILE_TYPES.FETCH_UPDATE_PROFILE_EMAIL_REQUEST, profileUpdateEmailSaga),
    takeLatest(PROFILE_TYPES.FETCH_VERIFY_PROFILE_EMAIL_REQUEST, profileVerifyEmailSaga),
    takeLatest(
      PROFILE_TYPES.FETCH_UPDATE_PROFILE_PHONE_NUMBER_REQUEST,
      profileUpdatePhoneNumberSaga,
    ),
    takeLatest(
      PROFILE_TYPES.FETCH_VERIFY_PROFILE_PHONE_NUMBER_REQUEST,
      profileVerifyPhoneNumberSaga,
    ),
    takeLatest(PROFILE_TYPES.FETCH_UPLOAD_PROFILE_PICTURE_REQUEST, profilePhotoUploadSaga),
    takeLatest(PROFILE_TYPES.FETCH_DELETE_PROFILE_PICTURE_REQUEST, profileDeletePhotoSaga),
    takeLatest(PROFILE_TYPES.SET_PROFILE_EMOJI_PICTURE, profileSetEmojiAsPhotoSaga),
    takeLatest(PROFILE_TYPES.SET_PROFILE_WONKA_PICTURE, profileSetWonkaAsPhotoSaga),
    takeLatest(PROFILE_TYPES.SET_PROFILE_EXTERNAL_NFT__PICTURE, profileSetExternalNFTAsPhotoSaga),

    takeLatest(PROFILE_TYPES.LOGOUT_REQUEST, logoutSaga),
    takeLatest(PROFILE_TYPES.FETCH_SEND_OTP_REQUEST, sendOTP),
    takeLatest(PROFILE_TYPES.FETCH_DELETE_ACC_OTP_REQUEST, sendDeleteAccOTP),
    takeLatest(PROFILE_TYPES.FETCH_DELETE_ACCOUNT_REQUEST, deleteAccount),
    takeLatest(PROFILE_TYPES.FETCH_GET_NOTIFICATIONS_REQUEST, getNotifications),
    takeLatest(PROFILE_TYPES.FETCH_SET_NOTIFICATIONS_REQUEST, setNotifications),

    takeLatest(PROFILE_TYPES.SET_WALLET_REQUEST, fetchSendWalletSaga),
    takeLatest(PROFILE_TYPES.FETCH_EXTERNAL_NFT_LIST, fetchExternalNFTs),
  ])
}
