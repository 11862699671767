import React, { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useQuery from '@hooks/useQuery'
import { API_URLS } from '@core/api/apiUrls'
import { configSelectors } from '@core/redux/selectors'
import { TCurrencyPrecision } from 'types/currencies'
import { getNFETransactionColumns } from '../main/Transactions.settings'
import TransactionsDetailsUi from './TransactionsDetails'

const ITEMS_COUNT_PER_PAGE = 10

function Transactions() {
  const currencies = useSelector(configSelectors.currencies)
  const [page, setPage] = useState(0)
  const [search] = useSearchParams()
  const code = search.get('code')
  const { t } = useTranslation('nsUserProfile')

  const columnsNames = t('columnsNames', { returnObjects: true })

  const currencyPrecision = useMemo(
    () =>
      currencies?.reduce(
        (acc, item) => ({ ...acc, [item.id]: item.precision }),
        {},
      ) as unknown as TCurrencyPrecision,
    [currencies],
  )

  const { data, loading, headers } = useQuery({
    url: API_URLS.token.purchase,
    params: {
      token: code,
      state: 'collected',
      page: page + 1,
      limit: ITEMS_COUNT_PER_PAGE,
    },
  })

  const handlePageChange = (currentPage) => {
    setPage(currentPage.selected)
  }

  const total = Number(headers?.total) || 0

  const columns = getNFETransactionColumns(currencyPrecision, currencies, columnsNames)

  return (
    <TransactionsDetailsUi
      columns={columns}
      data={data}
      loading={loading}
      total={total}
      handlePageChange={handlePageChange}
      page={page}
    />
  )
}

export default Transactions
