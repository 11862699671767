import { all } from 'typed-redux-saga'

import { rootSagaProfile } from './profile/sagas'
import { rootSagaAuth } from './auth/sagas'
import { rootSagaPurchasedTokens } from './purchasedTokens/sagas'
import { rootSagaCommon } from './common/sagas'

function* rootSaga() {
  yield* all([rootSagaProfile(), rootSagaAuth(), rootSagaPurchasedTokens(), rootSagaCommon()])
}

export default rootSaga
