import React, { Suspense, useEffect, useLayoutEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './i18n/config'

import PrivateRoute from '@routing/PrivateRoute'
import { isDevOrDevEnvironment } from '@constants/index'

import useEffectOnce from '@hooks/useEffectOnce'

import TestScreen from '@containers/Test'
import Header from '@components/UiKit/Header'
import Footer from '@components/UiKit/Footer'

import { configSelectors } from '@core/redux/selectors'
import { fetchProfile } from '@core/redux/profile/actions'
import AuthPopup from '@containers/AuthPopup'
import { useObserveNewWalletConnection } from '@hooks/useObserveNewWalletConnection'
import WalletAssets from '@components/Profile/WalletAssets'
import ProfileLayout from '@components/Profile/Layout'
import Transactions from '@containers/Profile/Transactions'
import Purchased from '@containers/Profile/Purchased'
// eslint-disable-next-line import/no-unresolved
import { useDisconnect } from '@web3modal/ethers/react'
import { fetchCurrencies } from '@core/redux/common/actions'
import routes from './routing/path'

import styles from './app.module.scss'

const SettingsLayout = React.lazy(() => import('./containers/Settings'))
const Placeholder = React.lazy(() => import('./containers/Placeholder'))
const Auth = React.lazy(() => import('./containers/Auth'))
function App() {
  console.log('v1.014')
  const { isAuth, authLoaded } = useSelector(configSelectors.isAuthenticated)
  const username = useSelector(configSelectors.username)
  const { disconnect } = useDisconnect()

  useObserveNewWalletConnection()

  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffectOnce(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useLayoutEffect(() => {
    dispatch(fetchProfile())
    dispatch(fetchCurrencies())
  }, [])

  useEffect(() => {
    if (!isAuth) {
      disconnect()
    }
  }, [isAuth])

  if (!authLoaded) {
    return null
  }

  return (
    <div className={styles.main_container}>
      <main id="main" className={styles.main}>
        <Header />

        <div className={styles.page_container}>
          <Suspense fallback="">
            <Routes>
              <Route
                path={routes.withAuth.home}
                element={
                  <Navigate to={routes.withAuth.profile.purchased.username.url(username)} replace />
                }
              />
              <Route path={routes.withoutAuth.auth} element={<Auth />} />
              <Route path={routes.withoutAuth.authPopup} element={<AuthPopup />} />

              <Route
                element={
                  <PrivateRoute>
                    <ProfileLayout />
                  </PrivateRoute>
                }
              >
                <Route path={routes.withAuth.profile.transactions} element={<Transactions />} />
                <Route
                  path={routes.withAuth.profile.purchased.username.url(username)}
                  element={<Purchased />}
                />
                <Route path={routes.withAuth.profile.walletNFTAssets} element={<WalletAssets />} />
              </Route>

              <Route
                path={routes.withAuth.profile.settings}
                element={
                  <PrivateRoute>
                    <SettingsLayout />
                  </PrivateRoute>
                }
              />

              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path={routes.withoutAuth.about} element={<Placeholder />} />
              <Route path={routes.withoutAuth.help} element={<Placeholder />} />
              <Route path={routes.withoutAuth.terms} element={<Placeholder />} />
              <Route path={routes.withoutAuth.privacy} element={<Placeholder />} />
              <Route path={routes.withoutAuth.blue} element={<Placeholder />} />
              <Route path={routes.withoutAuth.search} element={<Placeholder />} />
              <Route path={routes.withoutAuth.comingq4} element={<Placeholder />} />

              {isDevOrDevEnvironment && <Route path={routes.test} element={<TestScreen />} />}
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default App
