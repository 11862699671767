export enum AUTH_TYPES {
  FETCH_CHECK_USERNAME_REQUSET = 'FETCH_CHECK_USERNAME_REQUSET',
  FETCH_CHECK_USERNAME_SUCCESS = 'FETCH_CHECK_USERNAME_SUCCESS',
  FETCH_CHECK_USERNAME_FAILURE = 'FETCH_CHECK_USERNAME_FAILURE',

  FETCH_SEND_SESSION_OTP_REQUEST = 'FETCH_SEND_SESSION_OTP_REQUEST',
  FETCH_SEND_SESSION_OTP_SUCCESS = 'FETCH_SEND_SESSION_OTP_SUCCESS',
  FETCH_SEND_SESSION_OTP_FAILURE = 'FETCH_SEND_SESSION_OTP_FAILURE',

  FETCH_VERIFY_SIGNUP_REQUEST = 'FETCH_VERIFY_SIGNUP_REQUEST',
  FETCH_VERIFY_SIGNUP_SUCCESS = 'FETCH_VERIFY_SIGNUP_SUCCESS',
  FETCH_VERIFY_SIGNUP_FAILURE = 'FETCH_VERIFY_SIGNUP_FAILURE',

  VERIFY_SIGNIN_EMAIL_OTP_REQUEST = 'VERIFY_SIGNIN_EMAIL_OTP_REQUEST',
  VERIFY_SIGNIN_EMAIL_OTP_SUCCESS = 'VERIFY_SIGNIN_EMAIL_OTP_SUCCESS',
  VERIFY_SIGNIN_EMAIL_OTP_FAILURE = 'VERIFY_SIGNIN_EMAIL_OTP_FAILURE',

  VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL = 'VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL',
  VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL_SUCCESS = 'VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL_SUCCESS',
  VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL_FAILURE = 'VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL_FAILURE',

  VERIFY_SIGNUP_EMAIL_OTP_REQUEST = 'VERIFY_SIGNUP_EMAIL_OTP_REQUEST',
  VERIFY_SIGNUP_EMAIL_OTP_SUCCESS = 'VERIFY_SIGNUP_EMAIL_OTP_SUCCESS',
  VERIFY_SIGNUP_EMAIL_OTP_FAILURE = 'VERIFY_SIGNUP_EMAIL_OTP_FAILURE',

  VERIFY_PHONE_OTP_REQUEST = 'VERIFY_PHONE_OTP_REQUEST',
  VERIFY_PHONE_OTP_SUCCESS = 'VERIFY_PHONE_OTP_SUCCESS',
  VERIFY_PHONE_OTP_FAILURE = 'VERIFY_PHONE_OTP_FAILURE',

  VERIFY_UPDATE_PHONE_OTP_REQUEST = 'VERIFY_UPDATE_PHONE_OTP_REQUEST',
  VERIFY_UPDATE_PHONE_OTP_SUCCESS = 'VERIFY_UPDATE_PHONE_OTP_SUCCESS',
  VERIFY_UPDATE_PHONE_OTP_FAILURE = 'VERIFY_UPDATE_PHONE_OTP_FAILURE',

  SEND_USER_OTP_REQUEST = 'SEND_USER_OTP_REQUEST',
  SEND_USER_OTP_SUCCESS = 'SEND_USER_OTP_SUCCESS',
  SEND_USER_OTP_FAILURE = 'SEND_USER_OTP_FAILURE',

  FETCH_CREATE_NEW_USER_OTP_REQUEST = 'FETCH_CREATE_NEW_USER_OTP_REQUEST',
  FETCH_CREATE_NEW_USER_OTP_SUCCESS = 'FETCH_CREATE_NEW_USER_OTP_SUCCESS',
  FETCH_CREATE_NEW_USER_OTP_FAILURE = 'FETCH_CREATE_NEW_USER_OTP_FAILURE',

  FETCH_UPDATE_PHONE_REQUEST = 'FETCH_UPDATE_PHONE_REQUEST',
  FETCH_UPDATE_PHONE_SUCCESS = 'FETCH_UPDATE_PHONE_SUCCESS',
  FETCH_UPDATE_PHONE_FAILURE = 'FETCH_UPDATE_PHONE_FAILURE',

  FETCH_VERIFY_EMAIL_UPDATE_REQUEST = 'FETCH_VERIFY_EMAIL_UPDATE_REQUEST',
  FETCH_VERIFY_EMAIL_UPDATE_SUCCESS = 'FETCH_VERIFY_EMAIL_UPDATE_SUCCESS',
  FETCH_VERIFY_EMAIL_UPDATE_FAILURE = 'FETCH_VERIFY_EMAIL_UPDATE_FAILURE',

  SET_AUTH_LOADING = 'SET_AUTH_LOADING',

  SET_AUTH_FIELDS = 'SET_AUTH_FIELDS',

  SET_ROUTE_BEFORE_AUTH = 'SET_ROUTE_BEFORE_AUTH',

  SET_CAPTCHA = 'SET_CAPTCHA',
  RESET_CAPTCHA = 'RESET_CAPTCHA',
  SET_RESET_CAPTCHA_CB = 'SET_RESET_CAPTCHA_CB',

  SET_AUTH_STEP = 'SET_AUTH_STEP',
  GO_FORWARD_TO_AUTH_STEP = 'GO_FORWARD_TO_AUTH_STEP',
  GO_BACK_AUTH_STEP = 'GO_BACK_AUTH_STEP',
}
