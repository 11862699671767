import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react'
import classNames from 'classnames'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalEvents,
  useWeb3ModalState,
  // eslint-disable-next-line import/no-unresolved
} from '@web3modal/ethers/react'
import routes from '@routing/path'
import useDisableScroll from '@hooks/useDisableScroll'
import { configSelectors } from '@core/redux/selectors'
import { useSelector } from 'react-redux'
import Typography from '../Typography'
import styles from './styles.module.scss'

const { Label } = Typography

type AlignType = 'left' | 'middle' | 'right'
type SizeType = 'medium' | 'large'

export interface INav {
  key: string
  children: ReactNode
  href?: string
  to?: string
  onClick?: () => void
  isActive?: boolean
}

interface INavBarProps {
  isPlayaNFTsModal?: boolean
  items: INav[]
  align?: AlignType
  size?: SizeType
  fullWidth?: boolean
  wrapperClassName?: string
  itemClassName?: string
  onSelectTab?: (tabName: string) => void
}

function Item(props: any) {
  return <div {...props} />
}

function NavBar({
  isPlayaNFTsModal,
  items,
  fullWidth,
  align = 'left',
  size = 'medium',
  wrapperClassName,
  itemClassName,
  onSelectTab,
}: Readonly<INavBarProps>) {
  const location = useLocation()
  const navigate = useNavigate()
  const [activeItem, setActiveItem] = useState<string>(items[1]?.key)
  const { address, isConnected } = useWeb3ModalAccount()
  const { open } = useWeb3Modal()
  const events = useWeb3ModalEvents()
  const { open: isWeb3ModalOpened } = useWeb3ModalState()
  useDisableScroll(isWeb3ModalOpened)
  const showPFPModal = useSelector(configSelectors.showPFPModal)

  useEffect(() => {
    if (isPlayaNFTsModal) {
      setActiveItem(items[0].key)
    }
  }, [isPlayaNFTsModal])

  useEffect(() => {
    if (isPlayaNFTsModal) {
      setActiveItem(items[0].key)
    }
  }, [isPlayaNFTsModal])

  useEffect(() => {
    if (items.length === 1) {
      items.forEach((item) => {
        if (item.key === 'externalEmoji' && !address) {
          open()
        }
      })
    }
  }, [])

  useLayoutEffect(() => {
    items.forEach((i) => {
      if ((location.pathname === i.href || location.pathname === i.to) && !isPlayaNFTsModal) {
        return setActiveItem(i.key)
      }
      return false
    })
  }, [location.pathname, items])

  useEffect(() => {
    switch (events.data.event) {
      case 'CONNECT_SUCCESS':
        if (onSelectTab) {
          onSelectTab('externalEmoji')
          setActiveItem('externalEmoji')
          return
        }
        if (!showPFPModal) {
          navigate(routes.withAuth.profile.walletNFTAssets)
        }
        break
      case 'MODAL_CLOSE':
        const item = items.find((i) => {
          if ((location.pathname === i.href || location.pathname === i.to) && !isPlayaNFTsModal) {
            return true
          }
          return false
        })
        if (!events.data.properties.connected && item) setActiveItem(item?.key)
        break
      default:
        break
    }
  }, [events.timestamp])

  const onSelectItem = (event, item: INav) => {
    if ((item.key === 'items_wallet' || item.key === 'externalEmoji') && !address) {
      open()
      return
    }
    setActiveItem(item.key)

    if (onSelectTab) {
      onSelectTab(item.key)
      return
    }

    if (item.href) return
    event.preventDefault()

    if (item.to) {
      navigate(item.to)
    }

    if (item.onClick) {
      item.onClick()
    }
  }

  return (
    <nav
      className={classNames(
        styles.nav,
        styles[align],
        styles[size],
        {
          [styles.fullWidth]: fullWidth,
        },
        wrapperClassName,
      )}
    >
      <div className={styles.scrolledContent}>
        {items.map((item: INav) => {
          const isActive = item?.isActive
          if (!isActive && isActive !== undefined) return null

          const link = item.href
          const disabled = !address && item.key === 'items_wallet'
          const Component = link && !disabled ? NavLink : Item

          const props = {
            key: item.key,
            className: classNames(styles.nav_item, itemClassName, {
              [styles.active]: item.key === activeItem,
            }),
            onClick: (e) => onSelectItem(e, item),
          }

          const to = link || ''

          return (
            <Component to={to} {...props}>
              <Label size={5} className={styles.nav_item__content}>
                {item.children}
              </Label>
            </Component>
          )
        })}
      </div>
    </nav>
  )
}

export default React.memo<INavBarProps>(NavBar)
