import { TNullable } from 'types/utilityTypes'
import { TParams } from '@core/type'
import { ITransactionTypes } from '@components/Profile/Transactions/main/types'

import { createAction } from '@reduxjs/toolkit'
import { IReducerPurchaseTokens } from './type'
import { PURCHASED_TOKENS_TYPES } from './types'
import { TEmojiItemPurchased } from '../emojis/types'

export const fetchPurchasedTokens = createAction<{ params: { limit?: number; page?: number } }>(
  PURCHASED_TOKENS_TYPES.GET_PURCHASED_TOKENS_REQUEST,
)

export const fetchPurchasedTokensSuccess = createAction<IReducerPurchaseTokens>(
  PURCHASED_TOKENS_TYPES.GET_PURCHASED_TOKENS_SUCCESS,
)

export const fetchPurchasedTokensFailure = createAction<{ error: Error }>(
  PURCHASED_TOKENS_TYPES.GET_PURCHASED_TOKENS_FAILURE,
)

export const fetchTransactions = createAction<{
  transactionType: ITransactionTypes
  params: TParams
}>(PURCHASED_TOKENS_TYPES.GET_TRANSACTIONS_REQUEST)

export const fetchTransactionsSuccess = createAction<{
  transactionType: ITransactionTypes
  payload: {
    items: IReducerPurchaseTokens<TNullable<TEmojiItemPurchased[]>>
    total: TNullable<number>
    page: TNullable<number>
    perPage: TNullable<number>
  }
}>(PURCHASED_TOKENS_TYPES.GET_TRANSACTIONS_SUCCESS)

export const fetchTransactionsFailure = createAction<{
  transactionType: ITransactionTypes
  error: Error
}>(PURCHASED_TOKENS_TYPES.GET_TRANSACTIONS_FAILURE)
