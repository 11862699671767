import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal, { IModalType } from '@components/UiKit/Modal'
import Typography from '@components/UiKit/Typography'
import Button from '@components/UiKit/ButtonNew/Button'
import styles from './modal.module.scss'

const { Heading, Paragraph } = Typography

function UpdateCover({ open, onClose }: IModalType) {
  const { t } = useTranslation('nsSettings')
  return (
    <Modal open={open} onClose={onClose} className={styles.updateCoverWrapper}>
      <Heading size={3} className={styles.title}>
        {t('cover.update')}
      </Heading>

      <Paragraph font="Gilroy" size={2} className={styles.modal_paragraph}>
        {t('cover.format')}
      </Paragraph>

      <div className={styles.footer}>
        <Button type="submit" variant="primary" onClick={onClose}>
          {t('cover.uploadImage')}
        </Button>

        <Button
          type="submit"
          variant="secondary"
          className={styles.cancel_button}
          onClick={onClose}
        >
          {t('cover.cancel')}
        </Button>
      </div>
    </Modal>
  )
}

export default React.memo(UpdateCover)
