import { useSelector } from 'react-redux'
import routes from '@routing/path'
import { configSelectors } from '@core/redux/selectors'

export function createPagesList(t) {
  const username = useSelector(configSelectors.username)

  return [
    {
      children: t('items_wallet'),
      key: 'items_wallet',
      href: routes.withAuth.profile.walletNFTAssets,
    },
    {
      children: t('peerNfts'),
      key: 'Items',
      href: routes.withAuth.profile.purchased.username.url(username),
    },
    {
      children: t('activity'),
      key: 'activity',
      href: routes.withAuth.profile.transactions,
    },
  ]
}
