export enum IPlayaTypes {
  emoji = 'emoji',
  wonka = 'wonka',
  externalEmoji = 'externalEmoji',
}

export interface ITPlayaItem {
  children: string
  key: IPlayaTypes
}
