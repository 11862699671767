import { createAction } from '@reduxjs/toolkit'
import { IUserModel, Platform } from '@core/api/types'
import { AuthPages } from '@containers/Auth/types'
import { AUTH_TYPES } from './types'

export const fetchCheckIfUsernameAvailableRequest = createAction<{ userName: string }>(
  AUTH_TYPES.FETCH_CHECK_USERNAME_REQUSET,
)

export const fetchCheckIfUsernameAvailableSuccess = createAction(
  AUTH_TYPES.FETCH_CHECK_USERNAME_SUCCESS,
)

export const fetchCheckIfUsernameAvailableFailure = createAction(
  AUTH_TYPES.FETCH_CHECK_USERNAME_FAILURE,
)

export const fetchSendSessionOTPRequest = createAction<{
  identity: string
  captcha_response: string
  resend: boolean
}>(AUTH_TYPES.FETCH_SEND_SESSION_OTP_REQUEST)

export const fetchSendSessionOTPSuccess = createAction(AUTH_TYPES.FETCH_SEND_SESSION_OTP_SUCCESS)

export const fetchSendSessionOTPFailure = createAction(AUTH_TYPES.FETCH_SEND_SESSION_OTP_FAILURE)

export const fetchVerifySignup = createAction<{ identity: string; captcha_response: string }>(
  AUTH_TYPES.FETCH_VERIFY_SIGNUP_REQUEST,
)

export const fetchVerifySignupSuccess = createAction(AUTH_TYPES.FETCH_VERIFY_SIGNUP_SUCCESS)

export const fetchVerifySignupFailure = createAction(AUTH_TYPES.FETCH_VERIFY_SIGNUP_FAILURE)

export const verifySigninEmail = createAction<{
  identity: string
  verification_code: string
  platform: Platform
  captchaValue: string
}>(AUTH_TYPES.VERIFY_SIGNIN_EMAIL_OTP_REQUEST)

export const verifySigninEmailSuccess = createAction<{
  userData: { phone_number: string; full_name: string; username: string; dob: string }
  requestPayload: {
    identity: string
    verification_code: string
    platform: Platform
    captchaValue: string
  }
}>(AUTH_TYPES.VERIFY_SIGNIN_EMAIL_OTP_SUCCESS)

export const verifySigninEmailFailure = createAction<Error>(
  AUTH_TYPES.VERIFY_SIGNIN_EMAIL_OTP_FAILURE,
)

export const verifySigninPhoneAndUpdateEmail = createAction<{
  identity: string
  verification_code: string
  platform: Platform
  captchaValue: string
  email: string
}>(AUTH_TYPES.VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL)

export const verifySigninPhoneAndUpdateEmailSuccess = createAction(
  AUTH_TYPES.VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL_SUCCESS,
)

export const verifySigninPhoneAndUpdateEmailFailure = createAction<Error>(
  AUTH_TYPES.VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL_FAILURE,
)

export const verifySignupEmailRequest = createAction<{
  identity: string
  verification_code: string
  platform: Platform
}>(AUTH_TYPES.VERIFY_SIGNUP_EMAIL_OTP_REQUEST)

export const verifySignupEmailSuccess = createAction<{
  phone_number: string
  full_name: string
  username: string
  dob: string
}>(AUTH_TYPES.VERIFY_SIGNUP_EMAIL_OTP_SUCCESS)

export const verifySignupEmailFailure = createAction<Error>(
  AUTH_TYPES.VERIFY_SIGNUP_EMAIL_OTP_FAILURE,
)

export const verifyPhoneRequest = createAction<{
  identity: string
  verification_code: string
  platform: Platform
}>(AUTH_TYPES.VERIFY_PHONE_OTP_REQUEST)

export const sendUserOTP = createAction(AUTH_TYPES.SEND_USER_OTP_REQUEST)

export const verifyUpdatePhoneRequest = createAction<{
  identity?: string
  verification_code: string
  platform: Platform
  phone_number?: string
}>(AUTH_TYPES.VERIFY_UPDATE_PHONE_OTP_REQUEST)

export const verifyUpdatePhoneSuccess = createAction<IUserModel>(
  AUTH_TYPES.VERIFY_UPDATE_PHONE_OTP_SUCCESS,
)

export const verifyUpdatePhoneFailure = createAction<Error>(
  AUTH_TYPES.VERIFY_UPDATE_PHONE_OTP_FAILURE,
)

export const verifyPhoneSuccess = createAction<{
  phone_number: string
  full_name: string
  username: string
  dob: string
}>(AUTH_TYPES.VERIFY_PHONE_OTP_SUCCESS)

export const verifyPhoneFailure = createAction<Error>(AUTH_TYPES.VERIFY_PHONE_OTP_FAILURE)

export const setAuthFields = createAction<{ phone?: string; email?: string }>(
  AUTH_TYPES.SET_AUTH_FIELDS,
)

export const setAuthLoading = createAction<boolean>(AUTH_TYPES.SET_AUTH_LOADING)

export const fetchCreateNewUserOTP = createAction<{
  phone_number: string
  email: string
  captcha_response: string
  platform: Platform
  startTimer: VoidFunction
}>(AUTH_TYPES.FETCH_CREATE_NEW_USER_OTP_REQUEST)

export const fetchUpdatePhone = createAction<{
  phone_number: string
  platform: Platform
}>(AUTH_TYPES.FETCH_UPDATE_PHONE_REQUEST)

export const fetchUpdatePhoneSuccess = createAction(AUTH_TYPES.FETCH_UPDATE_PHONE_SUCCESS)

export const fetchUpdatePhoneFailure = createAction<Error>(AUTH_TYPES.FETCH_UPDATE_PHONE_FAILURE)

export const fetchCreateNewUserOTPSuccess = createAction(
  AUTH_TYPES.FETCH_CREATE_NEW_USER_OTP_SUCCESS,
)

export const fetchCreateNewUserOTPFailure = createAction<{
  err: Error
  requestPayload: {
    phone_number: string
    email: string
    captcha_response: string
    platform: Platform
  }
}>(AUTH_TYPES.FETCH_CREATE_NEW_USER_OTP_FAILURE)

export const fetchVerifyEmailUpdateRequest = createAction<{
  email: string
  verification_code: string
}>(AUTH_TYPES.FETCH_VERIFY_EMAIL_UPDATE_REQUEST)

export const fetchVerifyEmailUpdateSuccess = createAction<IUserModel>(
  AUTH_TYPES.FETCH_VERIFY_EMAIL_UPDATE_SUCCESS,
)

export const fetchVerifyEmailUpdateFailure = createAction<Error>(
  AUTH_TYPES.FETCH_VERIFY_EMAIL_UPDATE_FAILURE,
)

export const setCaptcha = createAction<string>(AUTH_TYPES.SET_CAPTCHA)

export const resetCaptcha = createAction(AUTH_TYPES.RESET_CAPTCHA)

export const setAuthStep = createAction<AuthPages>(AUTH_TYPES.SET_AUTH_STEP)

export const goForwardToAuthStep = createAction<AuthPages>(AUTH_TYPES.GO_FORWARD_TO_AUTH_STEP)

export const goBackAuthStep = createAction(AUTH_TYPES.GO_BACK_AUTH_STEP)
