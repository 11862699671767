import {
  isDevEnvironment,
  isDev,
  isUatEnvironment,
  isProductionEnvironment,
  isStageEnvironment,
} from '@constants/environments'

import envDev from './development'
import envProd from './production'
import envUat from './uat'
import envStage from './stage'
import { IEnv } from './types'

const getEnv = () => {
  let env: IEnv = {} as IEnv

  if (isDevEnvironment || isDev) {
    env = envDev
  }
  if (isStageEnvironment) {
    env = envStage
  }
  if (isUatEnvironment) {
    env = envUat
  }
  if (isProductionEnvironment) {
    env = envProd
  }

  window.env = env
  return env
}

export default getEnv()
